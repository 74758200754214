import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Tooltip } from '@mui/material';

import Task from './task/index';
import Spinner from '../../../common/spinner';
import SnackbarMessage from '../../../common/snackbarMessage';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../userOnBoarding/actions/actions';
import {
  getTasks,
  getTeamUserDetails,
  getUserDesignationDetails,
} from '../actions/home';
import {
  getAllMyReports,
  setSelectedInboxView,
  setInboxSearchText,
  setActiveUnassignedStage,
  setUnassignedSearchText,
} from '../actions/report';
import WorkflowOptions from '../component/workflowOptions';
import * as types from '../../../actions/types';
import '../styles/home.css';
import withRouter from 'withRouter';

interface IState {
  allReports: any[];
  openWorkflowOptions: boolean;
  selectedIndex: number | null;
  workflow: any;
}

interface IProps {
  teamId: string;
  getMyReports: boolean;
  allMyReports: any[];
  getMyReportsSpinner: boolean;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  getAllMyReports: (resolve: any) => void;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  setSelectedInboxView: any;
  setInboxSearchText: any;
  setActiveUnassignedStage: any;
  setUnassignedSearchText: any;
  getTasks: (pageSize: any, pageNumber: any) => void;
  getTeamUserDetails: (teamId: any, userId: any) => void;
  teamUserDetailsSuccess: boolean;
  userTeamDetails: any;
  userDetailsSpinner: boolean;
  getUserDesignationDetails: (designationId: string) => void;
  userDesignationDetails: any;
  userDesignationDetailsReqSuccess: boolean;
  userDesignationDetailsSpinner: boolean;
}

class Home extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      allReports: [],
      openWorkflowOptions: false,
      selectedIndex: null,
      workflow: null,
    };
  }

  componentDidMount() {
    this.loadGetReports();
    this.props.setSelectedInboxView(null);
    this.props.setInboxSearchText('');
    this.props.setActiveUnassignedStage(null);
    this.props.setUnassignedSearchText('');
    this.props.getTasks(10, 1);
    this.props.getTeamUserDetails(
      sessionStorage.getItem('teamId'),
      sessionStorage.getItem('userId')
    );
  }

  componentDidUpdate(nextProps: any) {
    if (this.props.teamId !== nextProps.teamId) {
      this.loadGetReports();
      this.closeWorkflowOptions();
      this.props.dispatch({
        type: types.RESET_REPORT_DETAILS_ON_TEAM_CHANGE,
      });
      this.props.getTasks(10, 1);
      this.props.getTeamUserDetails(
        this.props.teamId,
        sessionStorage.getItem('userId')
      );
    }
    if (this.props.getMyReports) {
      this.props.dispatch({
        type: types.RESET_GET_ALL_REPORTS_REQUEST,
      });
      this.generateReports(this.props.allMyReports);
    }
    if (this.props.teamUserDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_TEAM_USER_DETAILS_REQUEST,
      });
      const designationId =
        this.props.userTeamDetails &&
        this.props.userTeamDetails.designation &&
        this.props.userTeamDetails.designation.designation_id
          ? this.props.userTeamDetails.designation.designation_id
          : null;
      if (designationId) this.props.getUserDesignationDetails(designationId);
    }
    if (this.props.userDesignationDetailsReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_USER_DESIGNATION_DETAILS_REQUEST,
      });
    }
  }

  /**
   * get all reports
   */
  loadGetReports() {
    return new Promise((resolve) => {
      this.props.getAllMyReports(resolve);
    });
  }

  generateReports = (templates: any) => {
    const allReports: any = [];
    templates.length > 0 &&
      templates.map((item: any) => {
        if (item.access_to_me) {
          allReports.push(item);
        }
        return null;
      });
    this.setState({ allReports });
  };

  openWorkflowOptions = (index: number, workflow: any) => {
    if (!this.state.openWorkflowOptions || this.state.selectedIndex !== index) {
      this.setState(
        {
          openWorkflowOptions: true,
          selectedIndex: index,
          workflow,
        },
        () => {
          const element: any = document.getElementById('app-function-wrapper');
          element.scrollIntoView(true);
        }
      );
    } else {
      this.closeWorkflowOptions();
    }
  };

  closeWorkflowOptions = () => {
    this.setState({
      openWorkflowOptions: false,
      selectedIndex: null,
      workflow: null,
    });
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  resetReportDetails = () => {
    this.props.dispatch({
      type: types.RESET_REPORT_DETAILS_ON_TEAM_CHANGE,
    });
  };

  render() {
    return (
      <div id="home-root">
        <div className="apps-wrapper">
          <div className="header">
            <h5>Apps</h5>
          </div>
          {this.props.getMyReportsSpinner ? <Spinner /> : ''}
          <div className="app-cards-root">
            <div className="app-cards-wrapper">
              {this.state.allReports.length > 0 &&
                this.state.allReports.map((item: any, index: number) => {
                  return (
                    <Tooltip key={index} title={item.name}>
                      <div
                        className="app-card-wrapper"
                        onClick={() => this.openWorkflowOptions(index, item)}
                      >
                        <h6>{item.name}</h6>

                        <div className="rectangle-5">
                          <p>Details</p>
                          {this.state.selectedIndex !== index ? (
                            <i className="fas fa-caret-down" />
                          ) : (
                            ''
                          )}
                        </div>
                        {this.state.openWorkflowOptions &&
                        this.state.selectedIndex === index ? (
                          <div className="selected-wrapper">
                            <i className="fas fa-caret-down selected-workflow-icon" />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Tooltip>
                  );
                })}
            </div>
            {this.state.openWorkflowOptions ? (
              <WorkflowOptions
                workflow={this.state.workflow}
                resetReportDetails={this.resetReportDetails}
                userDesignation={this.props.userDesignationDetails}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <Task />
        <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage || this.props.success.displayMessage
          }
          open={this.props.open}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    getaccessTokenForTeam: state.homeReducer.getaccessTokenForTeam,
    getMyReports: state.reportReducer.getMyReports,
    getMyReportsSpinner: state.reportReducer.getMyReportsSpinner,
    allMyReports: state.reportReducer.allMyReports,
    teamId: state.drawerReducer.teamId,

    userTeamDetails: state.homeReducer.userTeamDetails,
    teamUserDetailsSuccess: state.homeReducer.teamUserDetailsSuccess,
    userDetailsSpinner: state.homeReducer.userDetailsSpinner,

    userDesignationDetails: state.homeReducer.userDesignationDetails,
    userDesignationDetailsReqSuccess:
      state.homeReducer.userDesignationDetailsReqSuccess,
    userDesignationDetailsSpinner:
      state.homeReducer.userDesignationDetailsSpinner,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getAllMyReports,
      setSelectedInboxView,
      setInboxSearchText,
      setActiveUnassignedStage,
      setUnassignedSearchText,
      getTasks,
      getTeamUserDetails,
      getUserDesignationDetails,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Home));
