import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';
import * as _ from 'lodash';
import { AppBar, Tabs, Tab, Typography, Button, Tooltip } from '@mui/material';

import Spinner from '../../../../common/spinner';
import SnackbarMessage from '../../../../common/snackbarMessage';
import * as types from '../../../../actions/types';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../userOnBoarding/actions/actions';
import {
  getReportDetails,
  getFormDataDetails,
  updateAssignee,
  getActivityLog,
} from '../../actions/report';
import {
  getTeamUserDetails,
  getUserDesignationDetails,
} from '../../actions/home';
import {
  checkPreCondition,
  getUserGroup,
  isNull,
  getAllVisibleTab,
  isAssignedToMe,
} from '../../../../utils/helper';
import AlertDailog from './../../component/alertDailog';
import ImagePreviewModal from './imagePreviewModal';
import LongTextModal from './longTextModal';
import LongtextField from './longTextField';
import DocumentPreviewModal from './documentPreviewModal';
import SimpleGridModal from './simpleGridModal';
import '../../styles/inbox/details.css';
import withRouter from 'withRouter';

interface IState {
  value: {
    index: number;
    displayName: string;
  };
  tabHeaders: any[];
  formDetails: any;
  fields: any[];
  primaryFields: any[];
  primaryImageField: any;
  phoneEmailFields: any[];
  updateFormCondition: boolean;
  openAssignToMeModal: boolean;
  isAssigneeNull: boolean;
  openImgModal: boolean;
  imgObj: any;
  openLongtxtModal: boolean;
  longtextData: any;
  openDocumentModal: boolean;
  fieldDocuments: any;
  openSimpleGridModal: boolean;
  simpleGridData: any;
  simpleGridFieldLabel: any;
}

interface IProps {
  getReportDetailsSuccess: boolean;
  getFormDetails: boolean;
  reportDetailsSpinner: boolean;
  getFormDetailsSpinner: boolean;
  reportDetails: any;
  formDataDetails: any;
  updateAssigneeSuccess: boolean;
  updateAssigneeSpinner: boolean;
  blobUrl: any;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  getReportDetails: (templateId: string, teamId: string, resolve: any) => void;
  getFormDataDetails: (
    templateId: string,
    instanceId: string,
    fields: any
  ) => void;
  updateAssignee: (updateAssigneeDetails: any) => void;
  getActivityLog: (templateId: string, instanceId: string) => void;
  activityLog: any;
  activityLogSuccess: boolean;
  activityLogSpinner: boolean;
  router: any;
  getTeamUserDetails: (teamId: string, userId: any) => void;
  userTeamDetails: any;
  teamUserDetailsSuccess: boolean;
  userDetailsSpinner: boolean;
  getUserDesignationDetails: (designationId: string) => void;
  userDesignationDetails: any;
  userDesignationDetailsReqSuccess: boolean;
  userDesignationDetailsSpinner: boolean;
}

function TabContainer(props: any) {
  return (
    <Typography
      component="div"
      style={{
        paddingTop: 30,
        height: '80%',
        width: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {props.children}
    </Typography>
  );
}

class Details extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: { index: 0, displayName: '' },
      tabHeaders: [],
      formDetails: {},
      fields: [],
      primaryFields: [],
      primaryImageField: null,
      phoneEmailFields: [],
      updateFormCondition: false,
      openAssignToMeModal: false,
      isAssigneeNull: false,
      openImgModal: false,
      imgObj: null,
      openLongtxtModal: false,
      longtextData: null,
      openDocumentModal: false,
      fieldDocuments: null,
      openSimpleGridModal: false,
      simpleGridData: null,
      simpleGridFieldLabel: '',
    };
  }

  componentDidMount() {
    this.loadData().then(() => {
      this.props.getTeamUserDetails(
        this.props.router.params.teamId,
        sessionStorage.getItem('userId')
      );
    });
  }

  componentDidUpdate() {
    if (this.props.getReportDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_REPORT_DETAILS_REQUEST,
      });
    }
    if (this.props.updateAssigneeSuccess) {
      this.props.dispatch({
        type: types.RESET_UPDATE_ASSIGNEE_REQUEST,
      });
      const template = this.props.reportDetails
        ? JSON.parse(this.props.reportDetails.template)
        : null;
      const allFields: any = template ? template.fields : [];
      this.props.getFormDataDetails(
        this.props.router.params.templateId,
        this.props.router.params.instanceId,
        allFields
      );
    }
    if (this.props.getFormDetails) {
      this.props.dispatch({
        type: types.RESET_GET_FORM_DETAILS_REQUEST,
      });
      this.setFormDataDetails();
    }
    if (this.props.teamUserDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_TEAM_USER_DETAILS_REQUEST,
      });
      if (
        !isNull(this.props.userTeamDetails) &&
        !isNull(this.props.userTeamDetails['designation']) &&
        !isNull(this.props.userTeamDetails['designation']['designation_id'])
      ) {
        this.props.getUserDesignationDetails(
          this.props.userTeamDetails['designation']['designation_id']
        );
      }
    }
    if (this.props.userDesignationDetailsReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_USER_DESIGNATION_DETAILS_REQUEST,
      });
      this.createHeaders(this.props.reportDetails);
      this.getFormDetails();
    }
  }

  /**
   * get report details
   */
  loadData() {
    return new Promise((resolve) => {
      this.props.getReportDetails(
        this.props.router.params.templateId,
        this.props.router.params.teamId,
        resolve
      );
    });
  }

  getFormDetails = () => {
    const template = this.props.reportDetails
      ? JSON.parse(this.props.reportDetails.template)
      : null;
    const allFields: any = template ? template.fields : [];
    this.props.getFormDataDetails(
      this.props.router.params.templateId,
      this.props.router.params.instanceId,
      allFields
    );
  };

  createHeaders = (data: any) => {
    const template = JSON.parse(data.template);
    let allFields: any = template.fields;
    template.fields.map((item: any) => {
      if (item.type === 'lookup') {
        if (item.fields && item.fields.length > 0)
          allFields = [...allFields, ...item.fields];
      }
      return null;
    });
    const secondaryFields = template.views.details.secondary;
    const primaryFields: any = [];
    const phoneEmailFields: any = [];
    let primaryImageField: any = null;

    template.views.details.primary.fields.length > 0 &&
      template.views.details.primary.fields.map((item: any) => {
        const field =
          allFields.length > 0 &&
          allFields.filter((item2: any) => item2.name === item);
        if (field.length > 0) {
          if (['phone', 'email'].includes(field[0].type)) {
            phoneEmailFields.push({
              type: field[0].type,
              name: item,
            });
          } else if (['image', 'signature'].includes(field[0].type)) {
            primaryImageField = item;
          } else {
            primaryFields.push(item);
          }
        }
        return null;
      });
    const userGroupIds = !isNull(this.props.userDesignationDetails)
      ? getUserGroup(this.props.userDesignationDetails)
      : [];
    const updatedStages = getAllVisibleTab([...secondaryFields], userGroupIds);

    this.setState({
      fields: template.fields,
      tabHeaders: [
        ...updatedStages,
        {
          name: '_mo_activity',
          display_name: 'Activity',
          fields: [],
          filter_options: [],
          permissions: { view: [], update: [] },
          pre_cond: 'None',
        },
      ],
      primaryFields,
      primaryImageField,
      phoneEmailFields,
      value: { index: 0, displayName: secondaryFields[0].display_name },
    });
  };

  setFormDataDetails = () => {
    const formDetails = _.omit(
      this.props.formDataDetails.data,
      '_mo_created_at',
      '_mo_updated_at',
      'instance_id'
    );

    const openAssignToMeModal = this.checkIsAssigneeNull(
      formDetails._mo_assignee
    );

    const stageViewIndex = this.state.tabHeaders.findIndex(
      (item) => item.name === this.props.formDataDetails._mo_stage
    );

    const index = stageViewIndex > 0 ? stageViewIndex : 0;

    this.checkButtonCondition(this.state.tabHeaders[index]);
    this.setState({
      formDetails,
      openAssignToMeModal,
      isAssigneeNull: openAssignToMeModal,
      value: {
        index: index,
        displayName: this.state.tabHeaders[index].display_name,
      },
    });
  };

  handleChange = (event: any, value: any) => {
    if (value !== this.state.tabHeaders.length - 1)
      this.checkButtonCondition(this.state.tabHeaders[value]);
    if (value === this.state.tabHeaders.length - 1) {
      this.props.getActivityLog(
        this.props.router.params.templateId,
        this.props.router.params.instanceId
      );
    }
    this.setState({
      value: { index: value, displayName: event.target.innerText },
    });
  };

  checkButtonCondition = (details: any) => {
    let preCondition = true;
    const permission =
      !isNull(this.props.formDataDetails) && details && details.name
        ? this.props.formDataDetails.permissions_ex[details.name]
        : {};
    const preExpression = details && details.pre_cond ? details.pre_cond : null;

    if (preExpression !== null) {
      preCondition = checkPreCondition(
        this.props.formDataDetails,
        preExpression
      );
    }
    const assignee =
      !isNull(this.props.formDataDetails) &&
      !isNull(this.props.formDataDetails._mo_assignee)
        ? this.props.formDataDetails._mo_assignee
        : null;
    const userDesigDetails =
      !isNull(this.props.userTeamDetails) &&
      !isNull(this.props.userTeamDetails.designation)
        ? this.props.userTeamDetails.designation
        : null;
    const assignToMe = isAssignedToMe(userDesigDetails, assignee);
    const updateFormCondition =
      permission && permission['update'] && preCondition && assignToMe;
    this.setState({ updateFormCondition });
  };

  /**
   * check the assignee of a record.
   * if assignee desig_id is null ,assign to self pop up will come
   */
  checkIsAssigneeNull = (assignee: any) => {
    if (!isNull(assignee)) {
      const { desig_id } = assignee;
      if (isNull(desig_id)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  getFormLabel = (name: string) => {
    let allFields: any = this.state.fields;
    this.state.fields.map((item: any) => {
      if (item.type === 'lookup') {
        if (item.fields && item.fields.length > 0)
          allFields = [...allFields, ...item.fields];
      }
      return null;
    });
    const field =
      allFields.length > 0 &&
      allFields.filter((item: any) => item.name === name);

    if (field.length > 0) {
      return field[0].label;
    }
    return name;
  };

  getTooltipValue = (value: any) => {
    return (
      <Tooltip
        title={value && value !== 'NA' ? value : ''}
        placement="bottom-start"
      >
        <h5>{value}</h5>
      </Tooltip>
    );
  };

  getCollaboratorValue = (data: any) => {
    const value: any = [];
    if (data) {
      data.length > 0 &&
        data.map((item: any) => {
          value.push(item.designation.designation_name);
          return null;
        });
    }
    const v = value.length > 0 ? value.join(',') : 'NA';
    return this.getTooltipValue(v);
  };

  getSelectValue = (data: any) => {
    const value: any = [];
    if (data) {
      data.length > 0 &&
        data.map((item: any) => {
          value.push(item);
          return null;
        });
    }
    const v = value.length > 0 ? value.join(',') : 'NA';
    return this.getTooltipValue(v);
  };

  openImagePriview = (imgs: any, name: string, index: number) => {
    const field = _.find(this.state.fields, { name });
    const imageList: any = [];
    imgs &&
      imgs.forEach((ele: any) => {
        imageList.push({
          original: ele,
        });
      });

    this.setState({
      openImgModal: true,
      imgObj: {
        imageList,
        label: field && field.label ? field.label : 'Image',
        startIndex: index,
      },
    });
  };

  closeModal = () => {
    this.setState({ openImgModal: false, imgObj: null });
  };

  getImageUI = (obj: any, name: string) => {
    if (obj && obj.length > 0) {
      /**
       * compact is to remove null values
       */
      const updateValue = _.compact(obj);
      const v = updateValue.map((item: any, index: number) => {
        return (
          <img
            key={index}
            src={item}
            alt="user"
            style={{
              width: 50,
              height: 50,
              margin: '0 5px 5px 0',
              cursor: 'pointer',
            }}
            onClick={() => this.openImagePriview(updateValue, name, index)}
          />
        );
      });
      return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{v}</div>;
    }
    return 'NA';
  };

  getLocationValue = (data: any) => {
    if (data) {
      const url = `http://maps.google.com/maps?q=${data.lat},${data.long}`;
      return (
        <a href={url} rel="noopener noreferrer" target="_blank">
          <i
            className="fas fa-map-marker-alt"
            style={{
              color: '#35c1b8',
              fontSize: '20px',
            }}
          />
        </a>
      );
    }
    return <h5>NA</h5>;
  };

  getDateValue = (data: any, mode: string, is_24hour: boolean) => {
    mode = (mode || '').trim().toLowerCase();
    let formattedDate: string;

    if (data) {
      switch (mode) {
        case 'time':
          formattedDate = is_24hour
            ? moment(data).format('HH:mm')
            : moment(data).format('h:mm a');
          break;
        case 'datetime':
          formattedDate = is_24hour
            ? moment(data).format('Do MMM YYYY HH:mm')
            : moment(data).format('Do MMM YYYY h:mm a');
          break;
        case 'date':
          formattedDate = moment(data).format('Do MMM YYYY');
          break;
        default:
          formattedDate = moment(data).format('Do MMM YYYY');

          break;
      }
    } else {
      formattedDate = 'NA';
    }

    return formattedDate;
  };

  getChecklistValue = (data: any) => {
    let v = 'NA';
    if (Array.isArray(data)) {
      v = `Items : ${data.length}`;
    }
    return this.getTooltipValue(v);
  };

  getCustomDataValue = (data: any) => {
    const v = data && data.label ? data.label : 'NA';
    return this.getTooltipValue(v);
  };

  getLookupValue = (data: any, labelKey: any) => {
    const v = data && data[labelKey] ? data[labelKey] : 'NA';
    return this.getTooltipValue(v);
  };

  getDefaultValue = (data: any) => {
    const v = data ? data : data === 0 ? 0 : 'NA';
    return this.getTooltipValue(v);
  };

  getUserDirectoryValue = (data: any) => {
    const value: any = [];
    if (!isNull(data)) {
      if (Array.isArray(data)) {
        data.forEach((element: any) => {
          value.push(
            `${element['desig_name'] ? element['desig_name'] : 'NA'}(${
              element['user_alias'] ? element['user_alias'] : 'NA'
            })`
          );
        });
      } else {
        value.push(
          `${data['desig_name'] ? data['desig_name'] : 'NA'}(${
            data['user_alias'] ? data['user_alias'] : 'NA'
          })`
        );
      }
    }
    const v = value.length > 0 ? value.join(',') : 'NA';
    return this.getTooltipValue(v);
  };

  openLongtextModal = (value: any, name: string) => {
    const field = _.find(this.state.fields, { name });
    this.setState({
      openLongtxtModal: true,
      longtextData: {
        value,
        label: field && field.label ? field.label : 'Longtext',
      },
    });
  };

  closeLngtextModal = () => {
    this.setState({ openLongtxtModal: false, longtextData: null });
  };

  getLongtextValue = (data: any, name: string) => {
    const value = !isNull(data) ? data : 'NA';
    return value === 'NA' ? (
      <div>
        <h5>{value}</h5>
      </div>
    ) : (
      <LongtextField
        value={value}
        name={name}
        openLongtextModal={this.openLongtextModal}
      />
    );
  };

  closeDocumentModal = () => {
    this.setState({ openDocumentModal: false, fieldDocuments: null });
  };

  getDocumentValue = (data: any) => {
    const value = !isNull(data) ? data : 'NA';
    return (
      <div
        onClick={() => {
          if (value !== 'NA' && !isNull(value) && value.length >= 1) {
            this.setState({ openDocumentModal: true, fieldDocuments: data });
          }
        }}
      >
        {value === 'NA' ? (
          <h5>NA</h5>
        ) : (
          <h5 style={{ color: '#007bff', cursor: 'pointer' }}>
            {value.length}
          </h5>
        )}
      </div>
    );
  };

  getSimpleGridValue = (field: any, data: any) => {
    const value = data && data.label ? data.label : 'NA';
    return (
      <div
        onClick={() => {
          if (value !== 'NA' && !isNull(value)) {
            this.setState({
              openSimpleGridModal: true,
              simpleGridData: data,
              simpleGridFieldLabel: field.label,
            });
          }
        }}
      >
        <h5
          style={value === 'NA' ? {} : { color: '#007bff', cursor: 'pointer' }}
        >
          {value}
        </h5>
      </div>
    );
  };

  getRatingFieldIcon = (icon: string | undefined): string => {
    switch (icon) {
      case 'smile':
      case 'face-smile':
        return 'smile';
      default:
        return icon || '';
    }
  };

  getRatingValue = (field: any, data: any) => {
    const value = data || 0;
    const noOfIcons = (field && field.additional_config && field.additional_config.no_of_icons) 
    ? field.additional_config.no_of_icons 
    : 5;

const icon = field && field.additional_config && field.additional_config.icon 
    ? this.getRatingFieldIcon(field.additional_config.icon) 
    : this.getRatingFieldIcon(undefined);


    return (
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            ...(noOfIcons > 5 ? { justifyContent: 'space-between' } : {}),
          }}
        >
          {Array.from(Array(noOfIcons).keys()).map((item) => {
            return (
              <div key={item} style={{ paddingRight: noOfIcons <= 5 ? 10 : 0 }}>
                <i
                  className={`${
                    value > 0 && item + 1 <= value ? 'fa' : 'far'
                  } fa-${icon}`}
                  style={{
                    fontSize: 25,
                    color:
                      value > 0 && item + 1 <= value
                        ? 'rgb(255, 212, 59)'
                        : '#828282',
                    cursor: 'pointer',
                  }}
                  onClick={() => {}}
                ></i>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  getFormValue = (name: any) => {
    const field = _.find(this.state.fields, { name });
    if (field !== undefined) {
      const data: any = this.state.formDetails[name];
      const mode = field.mode;
      const is_24hour = field.is_24hour;
      switch (field.type) {
        case 'image':
        case 'signature':
          return this.getImageUI(data, name);
        case 'simple-grid':
          return this.getSimpleGridValue(field, data);
        case 'customDataView':
          return this.getCustomDataValue(data);
        case 'lookup':
          return this.getLookupValue(data, field.labelKey);
        case 'collaborator':
          return this.getCollaboratorValue(data);
        case 'location':
          return this.getLocationValue(data);
        case 'date':
          return this.getDateValue(data, mode, is_24hour);
        case 'checklist':
          return this.getChecklistValue(data);
        case 'select':
          return this.getSelectValue(data);
        case 'user_directory':
          return this.getUserDirectoryValue(data);
        case 'longtext':
          return this.getLongtextValue(data, name);
        case 'document':
          return this.getDocumentValue(data);
        case 'rating':
          return this.getRatingValue(field, data);

        default:
          return this.getDefaultValue(data);
      }
    }
    return <h5>NA</h5>;
  };

  phoneUI = () => {
    const phoneIndex = _.findIndex(this.state.phoneEmailFields, {
      type: 'phone',
    });
    if (this.state.formDetails[this.state.phoneEmailFields[phoneIndex].name])
      return (
        <div className="contact-details">
          <i className="fas fa-phone" />
          <p>
            {phoneIndex >= 0
              ? this.state.formDetails[
                  this.state.phoneEmailFields[phoneIndex].name
                ]
              : 'NA'}
          </p>
        </div>
      );
    else return '';
  };

  emailUI = () => {
    const emailIndex = _.findIndex(this.state.phoneEmailFields, {
      type: 'email',
    });
    if (this.state.formDetails[this.state.phoneEmailFields[emailIndex].name])
      return (
        <div className="contact-details">
          <i className="fas fa-envelope-open-text" />
          <p>
            {emailIndex >= 0
              ? this.state.formDetails[
                  this.state.phoneEmailFields[emailIndex].name
                ]
              : 'NA'}
          </p>
        </div>
      );
    else return '';
  };

  updateForm = () => {
    const stageName = this.state.tabHeaders[this.state.value.index].name;
    this.props.router.navigate(
      `/member/${this.props.router.params.teamId}/inbox/${this.props.router.params.templateId}/update/${this.props.router.params.instanceId}/${stageName}`,
      {
        state: {
          returnPath: this.returnPath() === 'taskHome' ? 'home' : null,
        },
      }
    );
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  handleCloseAssignToMeModal = (option: string) => {
    if (option === 'yes') {
      this.setState(
        { openAssignToMeModal: false, isAssigneeNull: false },
        () => {
          const documents: any = [
            { instance_id: this.props.router.params.instanceId },
          ];
          this.props.updateAssignee({
            templateId: this.props.router.params.templateId,
            documents,
          });
        }
      );
    } else {
      this.setState({ openAssignToMeModal: false });
    }
  };

  getAssigneeOwner = () => {
    let owner = '';
    let assignee = '';
    const data = this.state.formDetails;
    if (!isNull(data)) {
      let assigneeField = '';
      let ownerField = '';
      if (data['_mo_assignee'] && typeof data['_mo_assignee'] === 'object') {
        assigneeField =
          typeof data['_mo_assignee']['user_alias'] !== 'undefined'
            ? data['_mo_assignee']['user_alias']
            : 'N/A';
        assignee = `${assigneeField} (Assignee)`;
      }
      if (data['_mo_owner'] && typeof data['_mo_owner'] === 'object') {
        ownerField = data['_mo_owner']['user_alias'];
        owner = `${ownerField} (Owner)`;
      }
    }

    return owner || assignee ? (
      <div className="field-data-wrapper" style={{ width: '100%' }}>
        <h5> {`${owner} | ${assignee}`}</h5>
      </div>
    ) : null;
  };

  renderPrimaryView = () => {
    return (
      <div className="form-details-content-primary">
        <div className="primary-img-wrapper">
          {this.state.primaryImageField &&
          !isNull(this.state.formDetails) &&
          this.state.formDetails[this.state.primaryImageField] &&
          this.state.formDetails[this.state.primaryImageField].length > 0 ? (
            <div className="primary-img">
              <img
                src={this.state.formDetails[this.state.primaryImageField][0]}
                alt="user"
                onClick={() =>
                  this.openImagePriview(
                    this.state.formDetails[this.state.primaryImageField],
                    this.state.primaryImageField,
                    0
                  )
                }
              />
            </div>
          ) : (
            <div className="primary-img" />
          )}
        </div>
        <div className="primary-fields">
          {this.getAssigneeOwner()}
          {this.state.primaryFields.length > 0 &&
            this.state.primaryFields.map((item: any, index: number) => {
              return (
                <div key={index} className="field-data-wrapper">
                  <h6>{this.getFormLabel(item)}</h6>
                  {this.getFormValue(item)}
                </div>
              );
            })}
          {_.find(this.state.phoneEmailFields, {
            type: 'phone',
          })
            ? this.phoneUI()
            : ''}
          {_.find(this.state.phoneEmailFields, {
            type: 'email',
          })
            ? this.emailUI()
            : ''}
        </div>
      </div>
    );
  };

  renderCreatedAt = (createdAt: any, createdBy: any) => {
    let userAlias =
      createdBy && createdBy.user_alias ? createdBy.user_alias : 'NA';
    let datetime = moment(createdAt).format("MMM Do 'YY  h:mm a");
    return <h5> {`${datetime} by ${userAlias}`}</h5>;
  };

  formatActivityValue = (item: any) => {
    let value = '';
    if (item.current_value) {
      switch (typeof item.current_value) {
        case 'object':
          value = item.action ? item.action : '';
          break;
        case 'string':
          value = item.current_value;
          break;
        default:
      }
    }
    return value;
  };

  renderActivityData = (item: any) => {
    return item.data.map((item2: any, index: number) => {
      let value = null;
      const label = item2.label ? item2.label : '';
      switch (item2.field_type) {
        case 'date':
          value = item2.current_value
            ? moment(item2.current_value, 'YYYY-MM-DD').format("MMM Do 'YY")
            : null;
          break;
        case 'time':
          value = item2.current_value
            ? moment(item2.current_value).format('h:mm a')
            : null;
          break;
        case 'datetime':
          value = item2.current_value
            ? moment(item2.current_value, 'YYYY-MM-DD').format(
                "MMM Do 'YY  h:mm a"
              )
            : null;
          break;
        case 'boolean':
        case 'switch':
          value = item2.current_value ? 'Yes' : 'No';
          break;
        default:
          value = this.formatActivityValue(item2);
          break;
      }

      return value && label ? (
        <div className="data-wrapper" key={`activity-value-${index}`}>
          <h6>{`${label} :`}</h6>
          <h5 style={{ paddingLeft: '5px' }}> {value}</h5>
        </div>
      ) : null;
    });
  };

  renderActivity = () => {
    if (!isNull(this.props.activityLog)) {
      return (
        <div style={{ width: '100%' }}>
          {this.props.activityLog.map((item: any, index: number) => {
            return (
              <div key={`activity-${index}`} className="activity-data-wrapper">
                {this.renderCreatedAt(item.created_at, item.created_by)}
                {this.renderActivityData(item)}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  returnPath = () => {
    return this.props.router.location.state &&
      this.props.router.location.state['returnPath']
      ? this.props.router.location.state['returnPath']
      : `inbox`;
  };

  render() {
    const returnPath = this.returnPath();
    return (
      <div id="report-form-details-root">
        <div className="form-details-header">
          <i
            className="fas fa-arrow-left"
            style={{ paddingRight: '20px' }}
            onClick={() => {
              if (returnPath === 'taskHome') {
                this.props.router.navigate(`/member/home`);
              } else {
                this.props.router.navigate(
                  `/member/${this.props.router.params.teamId}/${returnPath}/${this.props.router.params.templateId}`
                );
              }
            }}
          />
          <p>Details</p>
        </div>
        <div className="form-details-content">
          <div className="form-details-content-wrapper">
            {this.renderPrimaryView()}
            {this.props.reportDetailsSpinner ||
            this.props.getFormDetailsSpinner ||
            this.props.activityLogSpinner ||
            this.props.userDetailsSpinner ||
            this.props.userDesignationDetailsSpinner ? (
              <Spinner />
            ) : (
              ''
            )}
            <div className="form-details-content-secondary">
              <AppBar position="static" classes={{ root: 'appbar-root' }}>
                <Tabs
                  value={this.state.value.index}
                  onChange={this.handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  classes={{ indicator: 'indicator-bar' }}
                >
                  {this.state.tabHeaders.map((item: any, index: number) => {
                    return (
                      <Tab
                        key={index}
                        label={
                          item.display_name ? item.display_name : item.name
                        }
                        classes={{
                          root: 'button-root',
                          selected: 'selected-button',
                        }}
                        style={{
                          textTransform: 'none',
                        }}
                      />
                    );
                  })}
                </Tabs>
              </AppBar>
              {this.state.tabHeaders[this.state.value.index] &&
              this.state.value.index !== this.state.tabHeaders.length - 1 ? (
                <TabContainer key={this.state.value.index}>
                  {this.state.tabHeaders[this.state.value.index].fields.length >
                    0 &&
                    this.state.tabHeaders[this.state.value.index].fields.map(
                      (item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="field-data-wrapper w25-pb30"
                          >
                            <h6>{this.getFormLabel(item)}</h6>
                            {this.getFormValue(item)}
                          </div>
                        );
                      }
                    )}
                </TabContainer>
              ) : null}
              {this.state.value.index === this.state.tabHeaders.length - 1 ? (
                <TabContainer key={this.state.value.index}>
                  {this.renderActivity()}
                </TabContainer>
              ) : null}
              {this.state.value.index !== this.state.tabHeaders.length - 1 && (
                <div className="button-wrapper">
                  <Button
                    className={
                      this.state.updateFormCondition &&
                      !this.state.isAssigneeNull
                        ? 'form-update-button'
                        : 'form-update-disabled-button'
                    }
                    onClick={this.updateForm}
                    disabled={
                      !this.state.updateFormCondition ||
                      this.state.isAssigneeNull
                    }
                  >
                    Update {this.state.value.displayName}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage || this.props.success.displayMessage
          }
          open={this.props.open}
        />
        {this.state.openAssignToMeModal && returnPath === 'open' ? (
          <AlertDailog
            open={this.state.openAssignToMeModal}
            headerMessage="Assign to me ?"
            message="From the common pool, you can assign the selected record to yourself by clicking Yes.To only view the record, click No."
            handleClose={this.handleCloseAssignToMeModal}
          />
        ) : (
          ''
        )}
        {this.state.openImgModal ? (
          <ImagePreviewModal
            open={this.state.openImgModal}
            closeModal={this.closeModal}
            imgObj={this.state.imgObj}
          />
        ) : (
          ''
        )}
        {this.state.openLongtxtModal ? (
          <LongTextModal
            open={this.state.openLongtxtModal}
            closeModal={this.closeLngtextModal}
            data={this.state.longtextData}
          />
        ) : (
          ''
        )}
        {this.state.openDocumentModal && !isNull(this.state.fieldDocuments) ? (
          <DocumentPreviewModal
            open={this.state.openDocumentModal}
            closeModal={this.closeDocumentModal}
            data={this.state.fieldDocuments}
          />
        ) : null}
        {this.state.openSimpleGridModal && (
          <SimpleGridModal
            toggleModal={() =>
              this.setState({
                openSimpleGridModal: false,
                simpleGridData: null,
                simpleGridFieldLabel: '',
              })
            }
            simpleGridData={this.state.simpleGridData}
            fieldLabel={this.state.simpleGridFieldLabel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    getReportDetailsSuccess: state.reportReducer.getReportDetailsSuccess,
    reportDetailsSpinner: state.reportReducer.reportDetailsSpinner,
    reportDetails: state.reportReducer.reportDetails,
    getFormDetails: state.reportReducer.getFormDetails,
    getFormDetailsSpinner: state.reportReducer.getFormDetailsSpinner,
    formDataDetails: state.reportReducer.formDataDetails,
    updateAssigneeSuccess: state.reportReducer.updateAssigneeSuccess,
    updateAssigneeSpinner: state.reportReducer.updateAssigneeSpinner,
    blobUrl: state.reportReducer.blobUrl,
    activityLog: state.reportReducer.activityLog,
    activityLogSuccess: state.reportReducer.activityLogSuccess,
    activityLogSpinner: state.reportReducer.activityLogSpinner,
    userTeamDetails: state.homeReducer.userTeamDetails,
    teamUserDetailsSuccess: state.homeReducer.teamUserDetailsSuccess,
    userDetailsSpinner: state.homeReducer.userDetailsSpinner,
    userDesignationDetails: state.homeReducer.userDesignationDetails,
    userDesignationDetailsReqSuccess:
      state.homeReducer.userDesignationDetailsReqSuccess,
    userDesignationDetailsSpinner:
      state.homeReducer.userDesignationDetailsSpinner,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getReportDetails,
      getFormDataDetails,
      updateAssignee,
      getActivityLog,
      getTeamUserDetails,
      getUserDesignationDetails,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Details));
