import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';
import {
  TableFooter,
  TableRow,
  TablePagination,
  Table,
} from '@mui/material';

import { getTasks } from '../../actions/home';
import withRouter from 'withRouter';
import { isNull } from 'utils/helper';
import * as taskTypes from '../../../../actions/taskTypes';
import '../../styles/home.css';

interface IState {
  rowsPerPage: number;
  page: number;
}

interface IProps {
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  getTasksSuccess: boolean;
  getTasksSpinner: boolean;
  tasksList: any;
  router: any;
  getTasks: (pageSize: any, pageNumber: any) => void;
}

class Task extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      page: 0,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.getTasksSuccess) {
      this.props.dispatch({
        type: taskTypes.RESET_GET_TASKS_REQUEST,
      });
    }
  }

  navigateToFormo = (item: any) => {
    if (item.task_type === 'WORKFLOW') {
      const teamId = item.team_id ? item.team_id : null;
      const templateId =
        item.additional_data && item.additional_data.template_id
          ? item.additional_data.template_id
          : null;
      const instanceId =
        item.additional_data && item.additional_data.instance_id
          ? item.additional_data.instance_id
          : null;
      if (teamId && templateId && instanceId)
        this.props.router.navigate(
          `/member/${teamId}/inbox/${templateId}/details/${instanceId}`,
          {
            state: {
              returnPath: 'taskHome',
            },
          }
        );
    }
  };

  handleChangePage = (event: any, page: any) => {
    this.setState({ page }, () => {
      this.props.getTasks(this.state.rowsPerPage, page + 1);
      const element: any = document.getElementById('updates-wrapper');
      element.scrollIntoView(true);
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  pagination = () => {
    return (
      <Table>
        <TableFooter className="tabel-footer">
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={this.props.tasksList['total_count']}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  };

  render() {
    return (
      <div id="updates-wrapper">
        <div className="header task-header">
          <h5>Pending Tasks ({this.props.tasksList['total_count'] || 0})</h5>
        </div>
        <div className="task-content-wrapper">
          {!isNull(this.props.tasksList) &&
          this.props.tasksList['total_count'] &&
          this.props.tasksList['total_count'] > 0 &&
          !isNull(this.props.tasksList.tasks) ? (
            this.props.tasksList.tasks.map((item: any, index: number) => {
              return (
                <div
                  className="update-card-wrapper"
                  key={`task-${index}`}
                  onClick={() => {
                    this.navigateToFormo(item);
                  }}
                >
                  {item && item['due_date'] && (
                    <div className="date-time-wrapper">
                      <p className="date-time-card date-card">
                        <span>
                          <i className="fas fa-calendar date-time-icon" />
                        </span>
                        {moment(item['due_date']).format('DD MMM YY') || ''}
                      </p>
                      <p className="date-time-card time-card">
                        <span>
                          <i className="fas fa-clock date-time-icon" />
                        </span>
                        {moment(item['due_date']).format('hh:mm a') || ''}
                      </p>
                    </div>
                  )}
                  <p className="task-title">{item['title'] || ''}</p>
                </div>
              );
            })
          ) : (
            <p className="no-pending-tasks">No Pending Tasks</p>
          )}
        </div>
        {!isNull(this.props.tasksList) &&
        this.props.tasksList['total_count'] &&
        this.props.tasksList['total_count'] > 0
          ? this.pagination()
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    getTasksSuccess: state.homeReducer.getTasksSuccess,
    getTasksSpinner: state.homeReducer.getTasksSpinner,
    tasksList: state.homeReducer.tasksList,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators({ getTasks }, dispatch),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Task));
