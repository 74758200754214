import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  CircularProgress,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as _ from 'lodash';

import { getUserDirectoryDesignation } from '../../actions/home';
import { setSelectedFieldValue } from './../../actions/report';
import { getAssignmentType } from '../form/helper';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../userOnBoarding/actions/actions';
import * as types from '../../../../actions/types';
import SnackbarMessage from '../../../../common/snackbarMessage';
import SearchData from './../../component/searchData';
import { isNull } from 'utils/helper';
import './../../styles/lookup/lookup.css';
import withRouter from 'withRouter';

const styles: any = {
  root: {
    padding: '0 30px 10px',
    margin: 0,
  },
};

class UserDirectory extends Component<any, any> {
  wrapperRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      options: [],
      newSelected: [],
      searchText: '',
      showClearButton: false,
    };
  }

  componentDidMount() {
    this.callGetUserDirectoryDesignation();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps && prevProps.attributes.name !== this.props.attributes.name) {
      this.callGetUserDirectoryDesignation();
    }
    if (this.props.userDirectoryDesignationReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_USER_DIRECTORY_DESIGNATION_REQUEST,
      });
      this.initOptions();
    }
  }

  callGetUserDirectoryDesignation = () => {
    const attributes = this.props.attributes;
    if (
      !isNull(attributes) &&
      !isNull(attributes['config']) &&
      !isNull(attributes['config']['assignment_type'])
    ) {
      this.props.getUserDirectoryDesignation(
        getAssignmentType(attributes['config']['assignment_type'])
      );
    }
  };

  initOptions = () => {
    const attributes = this.props.attributes;
    const newSelected =
      !isNull(attributes) && !isNull(attributes['value'])
        ? attributes.multiple
          ? attributes['value'] || []
          : attributes['value']
        : [];
    const selectedDesignations =
      attributes &&
      attributes.config &&
      attributes.config.selected_designations &&
      Array.isArray(attributes.config.selected_designations) &&
      attributes.config.selected_designations.length > 0
        ? attributes.config.selected_designations
        : [];

    let designationsData = this.props.userDirectoryDesignation;

    // Check if show_first_option is true and there's exactly one selected designation
    if (
      attributes.config &&
      attributes.config.show_first_option &&
      selectedDesignations.length === 1 &&
      designationsData.length > 0
    ) {
      designationsData = designationsData.filter((data: any) =>
        selectedDesignations.includes(data.desig_id)
      );
    } else if (selectedDesignations.length > 0 && designationsData.length > 0) {
      designationsData = designationsData.filter((data: any) =>
        selectedDesignations.includes(data.desig_id)
      );
    }
    this.setState({
      options: designationsData,
      optionsCopy: designationsData,
      newSelected,
    });
  };

  searchData = (data: string) => {
    const attributes = this.props.attributes;
    const labelKey = attributes['labelKey'];
    const { searchText, optionsCopy } = this.state;
    if (searchText.trim() !== '' && data === 'search') {
      const options = _.filter(optionsCopy, (item: any) => {
        const userAliasSearch = !isNull(item['user_alias'])
          ? item['user_alias']
              .toString()
              .toLowerCase()
              .search(searchText.trim().toLowerCase()) > -1
          : false;

        const sItem =
          item[labelKey]
            .toString()
            .toLowerCase()
            .search(searchText.trim().toLowerCase()) > -1 || userAliasSearch;
        if (sItem) {
          return item;
        }
      });
      this.setState({
        showClearButton: true,
        options: !isNull(options) ? options : [],
      });
    } else {
      this.setState({
        options: optionsCopy,
        searchText: '',
        showClearButton: false,
      });
    }
  };

  onChangeSearchData = (event: any) => {
    this.setState(
      { searchText: event.target.value, showClearButton: false },
      () => {
        if (this.state.searchText.trim() === '') this.searchData('clear');
      }
    );
  };

  renderHeader = () => {
    const attributes = this.props.attributes;
    return (
      <div className="lookup-modal-title-wrapper lookup-new-wrapper user-directory-title-wrapper">
        <div className="lookup-new-close-icon user-directory-title-close-icon">
          <i
            className="far fa-times "
            onClick={() => {
              this.props.closeModal();
            }}
          />
        </div>
        <div className="lookup-new-title-search-wrapper">
          <div className="title">
            <p>{`${attributes['label']}`}</p>
          </div>
          <div className="search-filter-wrapper">
            <SearchData
              search={this.searchData}
              searchText={this.state.searchText}
              onChangeSearchData={this.onChangeSearchData}
              wrapperWidth="150px"
              background="white"
              searchInputWidth="100px"
              color="black"
              showClearButton={this.state.showClearButton}
            />
          </div>
        </div>
      </div>
    );
  };

  getSelecteValue = () => {
    const { newSelected } = this.state;
    let selectedValue = '';
    if (!isNull(newSelected) && Array.isArray(newSelected)) {
      newSelected.forEach((element: any) => {
        selectedValue = selectedValue + `${this.getLabel(element)}, `;
      });
    } else if (!isNull(newSelected)) {
      selectedValue = this.getLabel(newSelected);
    }

    return selectedValue;
  };

  handleAddPressed = () => {
    const { newSelected } = this.state;
    this.props.extProps.updateValue(this.props.attributes.name, newSelected);
    this.props.setSelectedFieldValue(
      true,
      `Selected value: ${this.getSelecteValue()}`
    );
    this.props.closeModal();
  };

  handleChange = (value: any) => {
    const attributes = this.props.attributes;
    let newSelected =
      attributes && attributes.multiple ? this.state.newSelected || [] : value;
    if (attributes && attributes.multiple) {
      newSelected = Array.isArray(newSelected) ? newSelected : [];
      const index = attributes.objectType
        ? newSelected.findIndex(
            (option: any) =>
              option[attributes.primaryKey] === value[attributes.primaryKey]
          )
        : newSelected.indexOf(value);
      if (index === -1) {
        newSelected.push(value);
      } else {
        newSelected.splice(index, 1);
      }
    }

    this.setState({ newSelected: newSelected });
  };

  getLabel = (item: any) => {
    if (item['desig_name']) {
      return `${item['desig_name']} (${
        item['user_alias'] ? item['user_alias'] : 'NA'
      })`;
    }
    return 'NA';
  };

  isOptionSelected = (pk: any, value: any, item: any) => {
    let isSelected = false;
    if (!isNull(value) && Array.isArray(value)) {
      isSelected =
        value.findIndex((option: any) => option[pk] === item[pk]) !== -1;
    } else if (!isNull(value)) {
      isSelected = value[pk] === item[pk];
    }
    return isSelected;
  };

  renderFormControlOption = (label: string, isSelected: boolean, item: any) => {
    const { attributes } = this.props;
    if (attributes['multiple']) {
      return (
        <Checkbox
          checked={isSelected}
          onChange={() => this.handleChange(item)}
          name={label}
          color={'primary'}
        />
      );
    } else {
      return (
        <Radio
          checked={isSelected}
          onChange={() => this.handleChange(item)}
          value={item}
          color="primary"
          name={label}
        />
      );
    }
  };

  renderData = () => {
    const { options } = this.state;
    if (this.props.userDirectoryDesignationSpinner) {
      return (
        <div className="lookup-data-spinner" style={{ width: '100%' }}>
          <CircularProgress
            style={{ width: '80px', height: '80px', color: '#6471de' }}
          />
        </div>
      );
    } else if (Array.isArray(options) && options.length) {
      const { classes, attributes } = this.props;
      const value = this.state.newSelected;
      const pk = attributes['primaryKey'];
      return (
        <FormControl component="fieldset" style={{ margin: 3 }}>
          <FormGroup>
            {options.map((item: any) => {
              const isSelected = this.isOptionSelected(pk, value, item);
              const label = this.getLabel(item);

              return (
                <FormControlLabel
                  key={item[pk]}
                  classes={{
                    root: classes.root,
                  }}
                  control={this.renderFormControlOption(
                    label,
                    isSelected,
                    item
                  )}
                  label={label}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      );
    } else {
      return <p className="no-records-found">No records found</p>;
    }
  };

  renderContent = () => {
    return (
      <div style={{ display: 'flex', height: '79%', overflowY: 'auto' }}>
        {this.renderData()}
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className="add-button-wrapper">
        <Button
          className={
            !isNull(this.state.options) && !isNull(this.state.newSelected)
              ? 'add-button'
              : 'add-button-disabled'
          }
          onClick={() => {
            if (!isNull(this.state.options) && !isNull(this.state.newSelected))
              this.handleAddPressed();
          }}
          disabled={
            isNull(this.state.options) || isNull(this.state.newSelected)
          }
        >
          Add
        </Button>
      </div>
    );
  };
  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  render() {
    return (
      <div className="lookup-modal-root select-root">
        <div id="lookup-modal-wrapper">
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage || this.props.success.displayMessage
          }
          open={this.props.open}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    userDirectoryDesignation: state.homeReducer.userDirectoryDesignation,
    userDirectoryDesignationReqSuccess:
      state.homeReducer.userDirectoryDesignationReqSuccess,
    userDirectoryDesignationSpinner:
      state.homeReducer.userDirectoryDesignationSpinner,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getUserDirectoryDesignation,
      setSelectedFieldValue,
    },
    dispatch
  ),
});

export default withRouter(
  connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UserDirectory))
);
