import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell, // Checkbox,
  TableFooter,
  TablePagination,
  TableHead,
  Badge,
  Tooltip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Classes } from 'jss';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';

import {
  getInboxDefaultQuery,
  isNull,
  getInboxViewConfig,
  getFormatedInboxData,
} from '../../../../utils/helper';
import {
  filterQuery,
  getLocalStorageTabs,
  getBulletFieldData,
  getInstanceIdData,
} from './../../component/helper';
import SnackbarMessage from '../../../../common/snackbarMessage';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../userOnBoarding/actions/actions';
import {
  getInboxData,
  getInboxInstanceIds,
  getReportDetails,
  getAllInboxConfigs,
  getInboxViewsData,
  setInboxTotalCount,
  setSelectedInboxView,
  setInboxSearchText,
} from '../../actions/report';
import {
  getTeamUserDetails,
  getUserDesignationDetails,
} from '../../actions/home';
import FilterData from './../../component/filterData';
import SearchData from './../../component/searchData';
import * as types from '../../../../actions/types';
import Spinner from '../../../../common/spinner';
import '../../styles/inbox/inbox.css';
import withRouter from 'withRouter';

const inboxViews = [
  'bullet_field',
  'primary_field',
  'secondary_field',
  'additional_field',
  'aggregate_field_2',
  'aggregate_field_1',
];

interface IState {
  page: number;
  rowsPerPage: number;
  headers: any;
  inboxData: any;
  openFilter: boolean;
  searchText: string;
  filterCount: number;
  allInboxConfigs: any;
  selectedView: any;
  showClearButton: boolean;
}

interface IProps {
  reportDetails: any;
  getInboxDataSuccess: boolean;
  inboxData: any;
  getReportDetailsSuccess: boolean;
  inboxDataSpinner: boolean;
  reportDetailsSpinner: boolean;
  classes: Classes;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  getInboxInstanceIdsSuccess: boolean;
  inboxInstancesIds: any;
  getInboxInstanceIdsSpinner: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  getInboxData: (templateId: string, instanceIds: any) => void;
  getInboxInstanceIds: (
    templateId: string,
    query: any,
    filterQuery: string
  ) => void;
  getReportDetails: (templateId: string, teamId: string, resolve: any) => void;
  getAllInboxConfigs: (templateId: string) => void;
  allInboxConfigReqSuccess: boolean;
  allInboxConfigs: any;
  inboxConfigSpinner: boolean;
  getInboxViewsData: any;
  setInboxTotalCount: any;
  inboxTotalCount: any;
  setSelectedInboxView: any;
  setInboxSearchText: any;
  inboxView: any;
  inboxSearchText: any;
  userTeamDetails: any;
  teamUserDetailsSuccess: boolean;
  getTeamUserDetails: (teamId: string, userId: any) => void;
  userDesignationDetails: any;
  userDesignationDetailsReqSuccess: boolean;
  getUserDesignationDetails: any;
  router: any;
}

const styles = {
  badge: {
    backgroundColor: '#4a90e2',
    color: '#ffffff',
  },
  root: {
    marginRight: 35,
  },
  switchLabel: {
    color: '#4a4a4a',
    fontSize: 14,
    margin: 0,
  },
};

class Inbox extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 25,
      headers: [],
      inboxData: [],
      openFilter: true,
      searchText: '',
      filterCount: 0,
      allInboxConfigs: [],
      selectedView: null,
      showClearButton: false,
    };
  }

  /**
   * Check filterObj exists in local storage.
   * If true then parse filterObj and check if template exists, if exists then generate the query
   */
  componentDidMount() {
    this.props.setInboxTotalCount(null);
    this.props.getTeamUserDetails(
      this.props.router.params.teamId,
      sessionStorage.getItem('userId')
    );
  }

  componentDidUpdate() {
    if (this.props.teamUserDetailsSuccess) {
      if (
        !isNull(this.props.userTeamDetails) &&
        !isNull(this.props.userTeamDetails['designation']) &&
        !isNull(this.props.userTeamDetails['designation']['designation_id'])
      ) {
        this.props.getUserDesignationDetails(
          this.props.userTeamDetails['designation']['designation_id']
        );
      }
      this.props.dispatch({
        type: types.RESET_GET_TEAM_USER_DETAILS_REQUEST,
      });
    }
    if (this.props.userDesignationDetailsReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_USER_DESIGNATION_DETAILS_REQUEST,
      });
      this.loadData();
    }
    if (this.props.getInboxInstanceIdsSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_INBOX_INSTANCEIDS_REQUEST,
      });
      this.getInboxDataReq();
    }
    if (this.props.getInboxDataSuccess) {
      this.props.dispatch({
        type: types.RESET_INBOX_DATA_REQUEST,
      });
      this.generateInboxData(this.props.inboxData);
    }
    if (this.props.getReportDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_REPORT_DETAILS_REQUEST,
      });
      this.props.getAllInboxConfigs(this.props.router.params.templateId);
    }
    if (this.props.allInboxConfigReqSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_ALL_INBOX_CONFIGS_REQUEST,
      });
      this.initHeader(this.props.allInboxConfigs, this.props.reportDetails);
    }
  }

  sortAllInboxConfig = (allInboxConfigs: any) => {
    if (!isNull(allInboxConfigs)) {
      const allConfigs = _.sortBy(allInboxConfigs, ['is_default']);
      return allConfigs.reverse();
    } else return [];
  };

  isDefaultInboxView = (allInboxConfigs: any, inboxConfig: any) => {
    if (isNull(allInboxConfigs) && isNull(inboxConfig)) {
      return false;
    } else {
      const config = _.find(allInboxConfigs, {
        is_default: true,
        name: inboxConfig['name'],
      });
      return !isNull(config) ? true : false;
    }
  };

  /**
   * When we click on inbox from home screen or on back click of details screen
   * based on the selected view we are making API request
   * If inboxview is non empty and non default we are calling callGetInboxViewsData
   * else we are calling get default inbox data
   */
  callGetInboxData = () => {
    if (
      !isNull(this.props.inboxView) &&
      !this.isDefaultInboxView(this.state.allInboxConfigs, this.props.inboxView)
    ) {
      this.setState({ selectedView: this.props.inboxView }, () => {
        const query = this.getQueryString();
        this.callGetInboxViewsData(query);
      });
    } else {
      this.getLocalStorageQuery();
    }
  };

  generateQuery = () => {
    const newFilter:any = {};
    const defaultFilter = getInboxDefaultQuery(this.props.reportDetails);
    newFilter['templateId'] = this.props.router.params.templateId;
    newFilter['userFilter'] = null;
    newFilter['defaultFilter'] = defaultFilter;
    return newFilter;
  };

  getInboxInstanceIdsReq = (query: any, queryString: any) => {
    this.props.getInboxInstanceIds(
      this.props.router.params.templateId,
      query,
      queryString
    );
  };

  callGetInboxViewsData = (query: any) => {
    this.props.getInboxViewsData(
      this.props.router.params.templateId,
      this.state.selectedView,
      query ? query : '',
      this.state.searchText ? this.state.searchText : '',
      this.state.page + 1,
      this.state.rowsPerPage
    );
  };

  getInboxDataReq = () => {
    if (
      this.isDefaultInboxView(
        this.state.allInboxConfigs,
        this.state.selectedView
      )
    ) {
      const instanceIds: any = [];
      const startIndex = this.state.page * this.state.rowsPerPage;
      const endIndex =
        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage;

      this.props.inboxInstancesIds
        .slice(startIndex, endIndex)
        .map((item: any) => {
          instanceIds.push(item);
          return null;
        });
      if (instanceIds.length > 0) {
        this.props.getInboxData(
          this.props.router.params.templateId,
          instanceIds.join(',')
        );
      } else {
        this.setState({ inboxData: [] });
      }
    } else {
      const query = this.getQueryString();
      this.callGetInboxViewsData(query);
    }
  };

  getQueryString = () => {
    const reportDetails = this.props.reportDetails;
    const fields =
      reportDetails &&
      reportDetails.template &&
      JSON.parse(reportDetails.template).fields;
    const tabs = getLocalStorageTabs(
      fields,
      this.props.router.params.templateId
    );
    const queryString = filterQuery(tabs);
    return queryString;
  };

  getLocalStorageQuery = () => {
    const reportDetails = this.props.reportDetails;
    let queryString = '';
    const fields =
      reportDetails &&
      reportDetails.template &&
      JSON.parse(reportDetails.template).fields;
    const tabs = getLocalStorageTabs(
      fields,
      this.props.router.params.templateId
    );
    queryString = filterQuery(tabs);
    const count = tabs.filter(
      (item: any) => item && item.value && item.value.length !== 0
    );
    this.setState({ filterCount: count.length });
    if (!isNull(this.state.searchText)) {
      queryString = `${queryString}&_ts_=${this.state.searchText}`;
    }
    const query = this.generateQuery();
    this.getInboxInstanceIdsReq(query, queryString);
  };

  /**
   * get report details
   */
  loadData() {
    return new Promise((resolve) => {
      this.props.getReportDetails(
        this.props.router.params.templateId,
        this.props.router.params.teamId,
        resolve
      );
    });
  }

  /**
   * Get user group ids
   */
  getUserGroupsIds = () => {
    const groups: any = [];
    if (
      !isNull(this.props.userDesignationDetails) &&
      !isNull(this.props.userDesignationDetails['groups'])
    ) {
      this.props.userDesignationDetails['groups'].forEach((element: any) => {
        groups.push(element['value']);
      });
    }
    return groups;
  };

  /**
   * Get inbox view access group ids
   */
  getAccessGrpIds = (accessgrp: any) => {
    const accessGroups: any = [];
    if (!isNull(accessgrp)) {
      accessgrp.forEach((element: any) => {
        accessGroups.push(element['id']);
      });
    }
    return accessGroups;
  };

  /**
   * Calculate user accessible inbox views
   */
  getAccessibleInboxViews = (allInboxConfigs: any) => {
    const userGroups = this.getUserGroupsIds();
    const accessibleInboxViews: any = [];
    if (!isNull(allInboxConfigs)) {
      allInboxConfigs.forEach((element: any) => {
        const accessGroupIds =
          !isNull(element) && !isNull(element.access_grp)
            ? this.getAccessGrpIds(element.access_grp)
            : [];
        const inboxViewAccess = accessGroupIds.some((item: any) =>
          userGroups.includes(item)
        );
        if (inboxViewAccess || element['is_default']) {
          accessibleInboxViews.push(element);
        }
      });
    }
    return accessibleInboxViews;
  };

  /**
   * Get table headers on change of inbox views
   */
  getHeaders = (stageName: string) => {
    const headers: any = [];
    const accessibleInboxViews = this.getAccessibleInboxViews(
      this.state.allInboxConfigs
    );
    const formatInboxConfig = this.sortAllInboxConfig(accessibleInboxViews);
    const defaultInboxView = getInboxViewConfig(formatInboxConfig, stageName);
    const fields =
      Object.keys(this.props.reportDetails).length > 0 &&
      !isNull(this.props.reportDetails.template) &&
      JSON.parse(this.props.reportDetails.template).fields;
    if (!isNull(defaultInboxView)) {
      inboxViews.map((item: any) => {
        if (defaultInboxView[item] && defaultInboxView[item]['value']) {
          headers.push(
            this.getInboxHeaderValue(
              fields,
              defaultInboxView[item]['label'],
              defaultInboxView[item]['value']
            )
          );
        } else {
          headers.push({ label: 'NA' });
        }
        return null;
      });
    }
    return headers;
  };

  initHeader = (allInboxConfigs: any, report: any) => {
    const headers: any = [];
    const accessibleInboxViews = this.getAccessibleInboxViews(allInboxConfigs);
    const formatInboxConfig = this.sortAllInboxConfig(accessibleInboxViews);
    const defaultInboxView = getInboxViewConfig(
      formatInboxConfig,
      this.getSelectedViewName()
    );
    const fields =
      Object.keys(report).length > 0 &&
      !isNull(report.template) &&
      JSON.parse(report.template).fields;
    if (!isNull(defaultInboxView)) {
      inboxViews.map((item: any) => {
        if (defaultInboxView[item] && defaultInboxView[item]['value']) {
          headers.push(
            this.getInboxHeaderValue(
              fields,
              defaultInboxView[item]['label'],
              defaultInboxView[item]['value']
            )
          );
        } else {
          headers.push({ label: 'NA' });
        }
        return null;
      });
    }
    this.setState(
      {
        headers,
        allInboxConfigs: formatInboxConfig,
        selectedView: formatInboxConfig[0],
        searchText: !isNull(this.props.inboxSearchText)
          ? this.props.inboxSearchText
          : '',
      },
      () => {
        this.callGetInboxData();
      }
    );
  };

  getInboxHeaderValue = (fields: any, label: any, value: any) => {
    if (value.length > 1) {
      if (label) {
        return { label: label };
      } else {
        const fieldName = value.replace('{{', '').replace('}}', '').split('.');
        if (fieldName.length > 1) {
          if (fieldName[fieldName.length - 1] === '_mo_created_at')
            return { label: 'Created at' };
          else if (fieldName[fieldName.length - 1] === '_mo_updated_at')
            return { label: 'Updated at' };
          else {
            const fieldLabel = fields.filter(
              (item: any) => item.name === fieldName[fieldName.length - 1]
            );
            return fieldLabel.length > 0
              ? { label: fieldLabel[0].label }
              : { label: fieldName[fieldName.length - 1] };
          }
        }
      }
    }
    return { label: 'NA' };
  };

  getSelectedViewName = () => {
    if (
      !isNull(this.state.selectedView) &&
      !this.isDefaultInboxView(
        this.state.allInboxConfigs,
        this.state.selectedView
      )
    ) {
      return this.state.selectedView['name']
        ? this.state.selectedView['name']
        : '';
    } else if (
      !isNull(this.props.inboxView) &&
      !this.isDefaultInboxView(this.state.allInboxConfigs, this.props.inboxView)
    ) {
      return this.props.inboxView['name'] ? this.props.inboxView['name'] : '';
    }
    return '';
  };

  generateInboxData = (data: any) => {
    const result = getFormatedInboxData(
      data,
      this.state.allInboxConfigs,
      this.getSelectedViewName()
    );
    const inboxData = !isNull(result) ? result : [];
    this.setState({ inboxData });
  };

  getLocationUI = (data: any) => {
    const url =
      !isNull(data) && !isNull(data.lat) && !isNull(data.long)
        ? `http://maps.google.com/maps?q=${data.lat},${data.long}`
        : undefined;

    if (!isNull(url))
      return (
        <a href={url} rel="noopener noreferrer" target="_blank">
          <i
            className="fas fa-map-marker-alt"
            style={{ color: '#35c1b8', fontSize: '20px' }}
          />
        </a>
      );
    else return '-';
  };

  getInboxDataValue = (data: any) => {
    if (data && data['value_type'] === 'location') {
      return this.getLocationUI(data['value']);
    } else if (data && data['value'] && Array.isArray(data['value'])) {
      const value: any = [];
      data['value'].map((item: any) => {
        if (item['designation'] && item['designation']['designation_name'])
          value.push(item['designation']['designation_name']);
        else value.push(item);
        return null;
      });
      return value.join(',');
    } else {
      const dataValue =
        data && data['value'] && typeof data['value'] !== 'object'
          ? data['value']
          : '-';
      return typeof dataValue === 'number'
        ? dataValue % 1 !== 0
          ? dataValue.toFixed(2)
          : dataValue
        : dataValue;
    }
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
      this.getInboxDataReq();
      setTimeout(() => {
        const element: any = document.getElementById('workflow-inbox-root');
        element.scrollIntoView({ behavior: 'smooth' });
      }, 2);
    });
  };

  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, () => {
      this.getInboxDataReq();
      setTimeout(() => {
        const element: any = document.getElementById('workflow-inbox-root');
        element.scrollIntoView({ behavior: 'smooth' });
      }, 2);
    });
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  handleChangeOpenFilter = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  applyFilter = (data: any) => {
    if (data.type === 'apply' && data.queryString !== '') {
      this.setState({ filterCount: data.count, page: 0 }, () => {
        const query = this.generateQuery();
        if (
          this.isDefaultInboxView(
            this.state.allInboxConfigs,
            this.state.selectedView
          )
        ) {
          let queryString = '';
          if (!isNull(this.state.searchText)) {
            const searchQuery = `_ts_=${this.state.searchText}`;
            queryString = `${data.queryString}&${searchQuery}`;
          } else {
            queryString = `${data.queryString}`;
          }

          this.getInboxInstanceIdsReq(query, queryString);
        } else {
          this.callGetInboxViewsData(data.queryString);
        }
      });
    } else {
      this.setState({ filterCount: 0, page: 0, searchText: '' }, () => {
        const query = this.generateQuery();
        if (
          this.isDefaultInboxView(
            this.state.allInboxConfigs,
            this.state.selectedView
          )
        ) {
          this.getInboxInstanceIdsReq(query, '');
        } else {
          this.callGetInboxViewsData('');
        }
      });
    }
  };

  onChangeSearchData = (event: any) => {
    this.setState(
      { searchText: event.target.value, showClearButton: false },
      () => {
        if (this.state.searchText.trim() === '') this.searchData('search');
      }
    );
  };

  getFilterQueryString = () => {
    let filterQueryString = '';
    const filterObj = localStorage.getItem('filterObj') || '';
    let filterFields: any = [];
    if (filterObj.trim()) {
      const filterArray = JSON.parse(filterObj);
      const templateIndex = _.findIndex(filterArray, {
        templateId: this.props.router.params.templateId,
      });
      if (templateIndex >= 0) {
        filterFields = filterArray[templateIndex].filterFields;
      }
    }
    if (filterFields.length > 0) {
      filterQueryString = filterQuery(filterFields);
    }
    return filterQueryString;
  };

  searchData = (data: any) => {
    if (data === 'search' && this.state.searchText.trim() !== '') {
      let queryString = '';
      const filterQueryString = this.getFilterQueryString();
      const searchQuery = `_ts_=${this.state.searchText}`;
      if (filterQueryString.length > 0)
        queryString = `${filterQueryString}&${searchQuery}`;
      else queryString = searchQuery;
      const query = this.generateQuery();
      this.setState({ page: 0, showClearButton: true }, () => {
        if (
          this.isDefaultInboxView(
            this.state.allInboxConfigs,
            this.state.selectedView
          )
        ) {
          this.getInboxInstanceIdsReq(query, queryString);
        } else {
          this.callGetInboxViewsData(
            filterQueryString.length > 0 ? filterQueryString : ''
          );
        }
      });
    } else {
      const query = this.generateQuery();
      const filterQueryString = this.getFilterQueryString();
      this.setState({ searchText: '', page: 0, showClearButton: false }, () => {
        if (
          this.isDefaultInboxView(
            this.state.allInboxConfigs,
            this.state.selectedView
          )
        ) {
          this.getInboxInstanceIdsReq(query, filterQueryString);
        } else {
          this.callGetInboxViewsData(
            filterQueryString.length > 0 ? filterQueryString : ''
          );
        }
      });
    }
  };

  handleChangeViews = (value: any) => {
    this.setState(
      {
        selectedView: value,
        page: 0,
        headers: this.getHeaders(value && value['name'] ? value['name'] : ''),
      },
      () => {
        if (this.isDefaultInboxView(this.state.allInboxConfigs, value)) {
          let queryString = '';
          const query = this.generateQuery();
          const filterQueryString = this.getFilterQueryString();

          if (filterQueryString.length > 0) {
            queryString = `${filterQueryString}`;
          }
          if (!isNull(this.state.searchText)) {
            const searchQuery = `_ts_=${this.state.searchText}`;
            queryString = `${queryString}&${searchQuery}`;
          }
          this.getInboxInstanceIdsReq(query, queryString);
        } else {
          this.getInboxDataReq();
        }
        setTimeout(() => {
          const element: any = document.getElementById('workflow-inbox-root');
          element.scrollIntoView({ behavior: 'smooth' });
        }, 2);
      }
    );
  };

  getTableCount = () => {
    if (
      this.isDefaultInboxView(
        this.state.allInboxConfigs,
        this.state.selectedView
      )
    ) {
      return !isNull(this.props.inboxInstancesIds)
        ? this.props.inboxInstancesIds.length
        : 0;
    } else {
      return this.props.inboxTotalCount ? this.props.inboxTotalCount : 0;
    }
  };

  render() {
    const returnPath =
      this.props.router.location.state &&
      this.props.router.location.state['returnPath']
        ? this.props.router.location.state['returnPath']
        : 'home';
    const spinner =
      this.props.inboxDataSpinner ||
      this.props.reportDetailsSpinner ||
      this.props.getInboxInstanceIdsSpinner ||
      this.props.inboxConfigSpinner;
    const { classes } = this.props;
    return (
      <div id="workflow-inbox-root">
        <div className="workflow-inbox-wrapper">
          <div className="workflow-inbox-header">
            <div className="workflow-inbox-title-wrapper">
              <i
                className="fas fa-arrow-left"
                style={{ paddingRight: '20px' }}
                onClick={() => {
                  this.props.setSelectedInboxView(null);
                  this.props.setInboxSearchText('');
                  this.props.router.navigate(`/member/${returnPath}`);
                }}
              />
              <Tooltip
                title={
                  this.props.reportDetails && this.props.reportDetails.name
                    ? this.props.reportDetails.name
                    : ''
                }
                placement="bottom-start"
              >
                <p>
                  Inbox -{' '}
                  {this.props.reportDetails
                    ? this.props.reportDetails.name
                    : ''}
                </p>
              </Tooltip>
            </div>
          </div>
          <div className="views-search-wrapper">
            <div className="views-picky">
              <p>Views</p>
              <Picky
                options={this.state.allInboxConfigs}
                value={this.state.selectedView}
                labelKey="name"
                valueKey="name"
                numberDisplayed={0}
                includeFilter={true}
                onChange={(data: any) => this.handleChangeViews(data)}
                dropdownHeight={250}
              />
            </div>
            <div className="workflow-inbox-filter-search">
              <Badge
                badgeContent={this.state.filterCount}
                classes={{ badge: classes.badge, root: classes.root }}
              >
                <i
                  className="fas fa-filter"
                  style={{
                    paddingRight: 5,
                    opacity: isNull(this.state.headers) ? 0.5 : 1,
                    cursor: isNull(this.state.headers) ? 'default' : 'pointer',
                  }}
                  onClick={() => {
                    if (!isNull(this.state.headers))
                      this.handleChangeOpenFilter();
                  }}
                />
              </Badge>
              <SearchData
                search={this.searchData}
                searchText={this.state.searchText}
                onChangeSearchData={this.onChangeSearchData}
                wrapperWidth="215px"
                background="linear-gradient(0deg, #f8f8f8 0%, #ececec 100%)"
                searchInputWidth="170px"
                color="#9b9b9b"
                disabled={isNull(this.state.headers)}
                disabledMsg="Views are not configured yet"
                showClearButton={this.state.showClearButton}
              />
            </div>
          </div>
          {spinner ? <Spinner /> : ''}
          {this.state.openFilter &&
          !isNull(this.state.headers) &&
          Object.keys(this.props.reportDetails).length > 0 ? (
            <FilterData
              template={this.props.reportDetails}
              applyFilter={this.applyFilter}
              templateId={this.props.router.params.templateId}
            />
          ) : null}
          <div className="workflow-inbox-content">
            {!isNull(this.state.headers) ? (
              <Paper className="paper-root overflowX">
                <Table className="table-wrapper">
                  <TableHead className="tabel-head-wrapper">
                    <TableRow>
                      <TableCell padding="checkbox" className="checkbox-color">
                        {/* <Checkbox
                    indeterminate={
                      this.props.data.selected.length > 0 &&
                      this.props.data.selected.length <
                        this.props.data.data.length
                    }
                    checked={
                      this.props.data.selected.length ===
                      this.props.data.data.length
                    }
                    onChange={this.props.handleSelectAllClick}
                    /> */}
                        {this.state.headers[0] && this.state.headers[0].label}
                      </TableCell>
                      {this.state.headers &&
                        this.state.headers
                          .slice(1)
                          .map((data: any, index: number) => {
                            if (data.label !== 'NA')
                              return (
                                <TableCell key={index}>{data.label}</TableCell>
                              );
                            return null;
                          })}
                    </TableRow>
                  </TableHead>
                  <TableBody className="tabel-body">
                    {this.state.inboxData.length > 0 &&
                      this.state.inboxData.map((row: any, index: number) => {
                        return (
                          <TableRow
                            hover={true}
                            role="checkbox"
                            // aria-checked={isSelected}
                            tabIndex={-1}
                            key={index}
                            // selected={isSelected}
                          >
                            <TableCell
                              padding="checkbox"
                              // onClick={(event: any) => console.log(event)}
                            >
                              {/* <Checkbox /> */}
                              <Link
                                to={`/member/${
                                  this.props.router.params.teamId
                                }/inbox/${
                                  this.props.router.params.templateId
                                }/details/${getInstanceIdData(row)}`}
                                state={{ returnPath: `inbox` }}
                                onClick={() => {
                                  this.props.setSelectedInboxView(
                                    this.state.selectedView
                                  );
                                  this.props.setInboxSearchText(
                                    this.state.searchText
                                  );
                                }}
                              >
                                {getBulletFieldData(row)}
                              </Link>
                            </TableCell>
                            {this.state.headers[1] &&
                              this.state.headers[1].label !== 'NA' && (
                                <TableCell>
                                  {this.getInboxDataValue(row['primary_field'])}
                                </TableCell>
                              )}
                            {this.state.headers[2] &&
                              this.state.headers[2].label !== 'NA' && (
                                <TableCell>
                                  {this.getInboxDataValue(
                                    row['secondary_field']
                                  )}
                                </TableCell>
                              )}
                            {this.state.headers[3] &&
                              this.state.headers[3].label !== 'NA' && (
                                <TableCell>
                                  {this.getInboxDataValue(
                                    row['additional_field']
                                  )}
                                </TableCell>
                              )}
                            {this.state.headers[4] &&
                              this.state.headers[4].label !== 'NA' && (
                                <TableCell>
                                  {this.getInboxDataValue(
                                    row['aggregate_field_2']
                                  )}
                                </TableCell>
                              )}
                            {this.state.headers[5] &&
                              this.state.headers[5].label !== 'NA' && (
                                <TableCell>
                                  {this.getInboxDataValue(
                                    row['aggregate_field_1']
                                  )}
                                </TableCell>
                              )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter className="tabel-footer">
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={this.getTableCount()}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            ) : spinner ? null : (
              <p style={{ paddingTop: '100px', textAlign: 'center' }}>
                Views are not configured yet
              </p>
            )}
          </div>
          <SnackbarMessage
            handleClose={this.handleClose}
            message={
              this.props.error.displayMessage ||
              this.props.success.displayMessage
            }
            open={this.props.open}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    getInboxDataSuccess: state.reportReducer.getInboxDataSuccess,
    inboxDataSpinner: state.reportReducer.inboxDataSpinner,
    inboxData: state.reportReducer.inboxData,
    getReportDetailsSuccess: state.reportReducer.getReportDetailsSuccess,
    reportDetailsSpinner: state.reportReducer.reportDetailsSpinner,
    reportDetails: state.reportReducer.reportDetails,
    getInboxInstanceIdsSuccess: state.reportReducer.getInboxInstanceIdsSuccess,
    inboxInstancesIds: state.reportReducer.inboxInstancesIds,
    getInboxInstanceIdsSpinner: state.reportReducer.getInboxInstanceIdsSpinner,
    allInboxConfigReqSuccess: state.reportReducer.allInboxConfigReqSuccess,
    inboxConfigSpinner: state.reportReducer.inboxConfigSpinner,
    allInboxConfigs: state.reportReducer.allInboxConfigs,
    inboxTotalCount: state.reportReducer.inboxTotalCount,
    inboxView: state.reportReducer.inboxView,
    inboxSearchText: state.reportReducer.inboxSearchText,
    userTeamDetails: state.homeReducer.userTeamDetails,
    teamUserDetailsSuccess: state.homeReducer.teamUserDetailsSuccess,
    userDesignationDetails: state.homeReducer.userDesignationDetails,
    userDesignationDetailsReqSuccess:
      state.homeReducer.userDesignationDetailsReqSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getInboxData,
      getReportDetails,
      getInboxInstanceIds,
      getAllInboxConfigs,
      getInboxViewsData,
      setInboxTotalCount,
      setSelectedInboxView,
      setInboxSearchText,
      getTeamUserDetails,
      getUserDesignationDetails,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Inbox)));
