import React, { Component } from 'react';
import Form from 'react-web-formo';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { customAlphabet } from 'nanoid'
import { Button } from '@mui/material';
import moment from 'moment';
import * as _ from 'lodash';
import queryString from 'query-string';
import Recaptcha from 'react-recaptcha';

import Spinner from '../../../../../../common/spinner';
// import SnackbarMessage from '../../../../../common/snackbarMessage';
import * as types from '../../../../../../actions/types';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../../../userOnBoarding/actions/actions';
import {
  getReportDetails,
  createNaveenFormData,
  setExtProps,
  setSelectedFieldValue,
  handleImagesUploadAndDownload,
  handleDocumentUpload,
  accessTokenForGuestUser,
  setGuestFormdata,
  getOTPForForm,
} from '../../../../actions/report';
import { getUserDirectoryDesignation } from 'feature/workflow/actions/home';
import { isNull, unsupportedMessage } from '../../../../../../utils/helper';
import CatalogNaveenEnt from './../../guestUser/catalogNaveenEnt';
import Lookup from './../../../lookup';
import Checklist from './../../../checklist';
import Select from './../../../select';
import UserDirectory from './../../../userDirectory';
import ImagePreviewModal from './../../../inbox/imagePreviewModal';
import AlertDailog from './../../../../component/alertDailog';
import {
  getAssignmentType,
  deleteDocumentFile,
  downloadDocument,
  processUserDirectoryField,
} from './../../helper';
import { setCustomToken } from './../../../../../../fireStoreConfig/fireStore';
import OTPModal from './../../guestUser/otpModal';
import '../../../../styles/form/guestUserForm.css';
import withRouter from 'withRouter';

const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@',10)

interface IState {
  fields: any[];
  templateName: string;
  openCatalogModal: boolean;
  extProps: any;
  openLookupModal: boolean;
  formData: any;
  openChecklistModal: boolean;
  openSelectModal: boolean;
  openUserDirectoryModal: boolean;
  attachmentUploadStatus: string;
  isImagePicked: boolean;
  openImgModal: boolean;
  modalImgData: any;
  openAlertDialog: boolean;
  alertMessage: string;
  docUpload: boolean;
  deleteDocument: boolean;
  deleteDocDialog: boolean;
  deleteDocData: any;
  noGuestAccessToken: boolean;
  isVerified: boolean;
  refData: any;
  openOtpModal: boolean;
  contactInfo: {
    email: string;
    phoneNumber: any;
  };
}

interface IProps {
  getReportDetailsSuccess: boolean;
  naveenCreateFormDataSuccess: boolean;
  reportDetails: any;
  naveenCreateFormDataResponse: any;
  reportDetailsSpinner: boolean;
  naveenCreateFormDataSpinner: boolean;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  getReportDetails: (templateId: string, teamId: string, resolve: any) => void;
  userTeamDetails: any;
  getUserDirectoryDesignation: any;
  createNaveenFormData: (
    templateId: string,
    formData: any,
    publicId: string
  ) => void;
  setExtProps: (extProps: any) => void;
  selectedMsg: string;
  setSelectedFieldValue: any;
  handleImagesUploadAndDownload: any;
  handleDocumentUpload: any;

  guestUserSpinner: boolean;
  guestUserAccessTokenSuccess: boolean;
  accessTokenForGuestUser: (teamId: string) => void;
  guestUserAccessToken: any;
  guestTeamId: string;
  guestTemplateId: string;
  guestUserAccessTokenFailure: boolean;
  setGuestFormdata: (guestFormDetails: any) => void;
  router: any;
  getOTPForForm: (
    userName: string,
    loginType: string,
    resolve: any,
    reject: any
  ) => void;
  validateGuestUserOtpRequest: boolean;
  validateGuestUserOtpSuccess: boolean;
}

class NEStoreOrderForm extends Component<IProps, IState> {
  state = {
    fields: [],
    templateName: '',
    openCatalogModal: false,
    extProps: {},
    openLookupModal: false,
    formData: null,
    openChecklistModal: false,
    openSelectModal: false,
    openUserDirectoryModal: false,
    attachmentUploadStatus: 'done',
    isImagePicked: false,
    openImgModal: false,
    modalImgData: null,
    openAlertDialog: false,
    alertMessage: '',
    docUpload: false,
    deleteDocument: false,
    deleteDocDialog: false,
    deleteDocData: {},
    noGuestAccessToken: false,
    refData: {},
    isVerified: process.env.NODE_ENV === 'development' ? true : false,
    openOtpModal: false,
    contactInfo: {
      email: '',
      phoneNumber: null,
    },
  };
  startTime = moment().utc().valueOf();
  instanceId=nanoid()
  formGenerator: any;

  componentDidMount() {
    // this.initFormdata();
    this.props.setGuestFormdata({});
    this.props.dispatch({
      type: types.RESET_REPORT_DETAILS_ON_TEAM_CHANGE,
    });
    this.props.accessTokenForGuestUser(this.props.router.params.publicId);
  }

  componentDidUpdate() {
    if (this.props.guestUserAccessTokenSuccess) {
      this.props.dispatch({
        type: types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
      });
      this.getTemplateDetails();
      setCustomToken(sessionStorage.getItem('accessToken'));
    }

    if (this.props.guestUserAccessTokenFailure) {
      this.props.dispatch({
        type: types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
      });
      this.setNoGuestAccessToken();
    }

    if (this.props.getReportDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_REPORT_DETAILS_REQUEST,
      });
      this.initStageFields(this.props.reportDetails);
    }

    if (this.props.naveenCreateFormDataSuccess) {
      this.props.dispatch({
        type: types.RESET_NAVEEN_CREATE_FORM_DATA_REQUEST,
      });
      this.props.router.navigate(`/guest/success`);
    }
    if (this.props.validateGuestUserOtpSuccess) {
      this.props.dispatch({
        type: types.RESET_VALIDATE_GUEST_USER_OTP_REQUEST,
      });
      this.closeOtpModal();
      this.submitForm();
    }
  }

  initFormdata = () => {
    const searchParams = this.props.router.location.search;
    this.setState({ formData: queryString.parse(searchParams) });
  };

  setNoGuestAccessToken = () => {
    this.setState({ noGuestAccessToken: true });
  };

  /**
   * get report details
   */
  getTemplateDetails() {
    if (!isNull(this.props.guestUserAccessToken)) {
      new Promise((resolve) => {
        this.props.getReportDetails(
          this.props.guestTemplateId,
          this.props.guestTeamId,
          resolve
        );
      });
    } else {
      this.setNoGuestAccessToken();
    }
  }

  /**
   * Stage fields for form0
   */
  initStageFields = async (workflow: any) => {
    const formatTemplate =
      !isNull(workflow) && !isNull(workflow.template)
        ? JSON.parse(workflow.template)
        : null;
    const fields =
      !isNull(formatTemplate) && !isNull(formatTemplate.fields)
        ? formatTemplate.fields
        : [];

    const promises: Promise<void>[] = [];
    const data: any = [];
    const stageFields: any =
      !isNull(formatTemplate) &&
      !isNull(formatTemplate.views) &&
      !isNull(formatTemplate.views.create) &&
      !isNull(formatTemplate.views.create.fields)
        ? formatTemplate.views.create.fields
        : [];

    if (fields.length > 0 && stageFields.length > 0) {
      stageFields.map((item: any) => {
        let field = _.find(fields, {
          name: item,
        });
        if (field) {
          if (field.type === 'user_directory') {
            const assignmentType = getAssignmentType(
              field.config.assignment_type
            );
            const promise = new Promise<void>((resolve, reject) => {
              this.props.getUserDirectoryDesignation(
                assignmentType,
                resolve,
                reject
              );
            }).then((designationData: any) => {
              processUserDirectoryField(
                field,
                designationData,
                this.props.userTeamDetails
              );
            });
            promises.push(promise);
          }
          /**
           * For status_picker and picker, get stage specific options to render and add -Select- as first value
           */
          if (['status_picker', 'picker'].includes(field.type)) {
            if (field.filter_options && field.filter_options.length > 0) {
              field = this.updateOption(field);
            } else {
              const options = field['options'];
              const index = _.findIndex(
                options,
                (option: any) => option === '-Select-'
              );
              if (index === -1) options.unshift('-Select-');
              field['options'] = options;
            }
          }
          data.push(field);
        }
        return null;
      });
    }
    await Promise.all(promises);
    this.setState({
      fields: data,
      templateName: this.props.reportDetails.name,
    });
  };

  /**
   * Get stage specific options to render and add -Select- as first value
   */
  updateOption = (field: any) => {
    const options = field.filter_options;
    const stage = 'create';
    const filterOption = _.find(options, stage);

    if (filterOption) {
      const stageOption = filterOption[stage];
      const index = _.findIndex(
        stageOption,
        (option: any) => option === '-Select-'
      );
      if (index === -1) stageOption.unshift('-Select-');
      field['options'] = stageOption;
    }
    return field;
  };

  scrollToTop = () => {
    setTimeout(() => {
      const element: any = document.getElementById(
        'guest-user-create-form-root'
      );
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  };

  openCustomDataView = (extProps: any) => {
    this.closeLookupModal();
    this.closeChecklist();
    this.closeSelectModal();
    this.closeUserDirectoryModal();
    this.setState({ openCatalogModal: true, extProps });
  };

  closeCustomDataView = () => {
    this.setState({ openCatalogModal: false });
  };

  openLookupView = (extProps: any) => {
    this.closeCustomDataView();
    this.closeChecklist();
    this.closeSelectModal();
    this.closeUserDirectoryModal();
    this.props.setExtProps(extProps);
    this.setState({ openLookupModal: true, extProps });
  };

  closeLookupModal = () => {
    this.setState({ openLookupModal: false });
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
    this.props.setSelectedFieldValue(false, '');
  };

  /**
   * Add checklist view
   */
  openCheckList = (extProps: any) => {
    this.closeCustomDataView();
    this.closeLookupModal();
    this.closeSelectModal();
    this.closeUserDirectoryModal();
    this.setState({ openChecklistModal: true, extProps });
  };

  closeChecklist = () => {
    this.setState({ openChecklistModal: false });
  };

  /**
   * Open select modal
   */
  openSelect = (extProps: any) => {
    this.closeCustomDataView();
    this.closeLookupModal();
    this.closeChecklist();
    this.closeUserDirectoryModal();
    this.setState({ openSelectModal: true, extProps });
  };

  closeSelectModal = () => {
    this.setState({ openSelectModal: false });
  };

  /**
   * Open user directory modal
   */
  openUserDirectory = (extProps: any) => {
    this.closeCustomDataView();
    this.closeLookupModal();
    this.closeChecklist();
    this.closeSelectModal();
    this.setState({ openUserDirectoryModal: true, extProps });
  };

  closeUserDirectoryModal = () => {
    this.setState({ openUserDirectoryModal: false });
  };

  getInstanceId = () => {
    return this.instanceId;
  };

  /**
   * Add _mo_instance_id,_mo_start_time and _mo_end_time values to form data
   */
  submitForm = () => {
    const formData = this.formGenerator.getValues();
    if (formData) {
      const endTime = moment().utc().valueOf();
      const recordedTime = moment().utc().valueOf();
      formData['_mo_start_time'] = this.startTime;
      formData['_mo_recorded_at'] = recordedTime;
      formData['_mo_end_time'] = endTime;
      formData['_mo_instance_id'] = this.instanceId;
      this.props.setGuestFormdata(formData);
      this.props.createNaveenFormData(
        this.props.guestTemplateId,
        formData,
        this.props.router.params.publicId
      );
    }
  };

  getTitle = () => {
    return `${this.state.templateName}`;
  };

  getButtonTitle = () => {
    if (
      this.state.attachmentUploadStatus === 'inProgress' ||
      this.state.docUpload
    ) {
      return `Uploading`;
    } else if (this.state.deleteDocument) {
      return 'Deleting record';
    }
    return `Submit`;
  };

  renderComponent = (extProps: any) => {
    const { attributes }:any = extProps;
    if (attributes && typeof attributes['type'] !== 'undefined') {
      const { type } = attributes;
      switch (type) {
        case 'lookup':
          this.openLookupView(extProps);
          break;
        case 'customDataView':
          this.openCustomDataView(extProps);
          break;
        case 'checklist':
          this.openCheckList(extProps);
          break;
        case 'select':
          this.openSelect(extProps);
          break;
        case 'user_directory':
          this.openUserDirectory(extProps);
          break;
      }
    }
  };

  handleImagesUploadAndDownload = (
    attributes: any,
    doc: any,
    actionType: any
  ) => {
    try {
      if (typeof doc !== 'undefined' && !isNull(doc) && attributes) {
        this.setState({
          attachmentUploadStatus: 'inProgress',
        });

        new Promise((resolve, reject) => {
          this.props.handleImagesUploadAndDownload(
            this.props.guestTemplateId,
            this.props.guestTeamId,
            this.getInstanceId(),
            attributes['name'],
            doc,
            actionType,
            resolve,
            reject
          );
        })
          .then((data) => {
            if (!isNull(data) && Array.isArray(data) && data.length) {
              this.setState(
                {
                  attachmentUploadStatus: 'done',
                  isImagePicked: actionType === 'write' ? true : false,
                },
                () => {
                  this.formGenerator.onValueChange(attributes['name'], data);
                }
              );
            }
          })
          .catch(() => {
            this.setState(
              {
                attachmentUploadStatus: 'done',
              },
              () => {
                this.formGenerator.onValueChange(attributes['name'], null);
              }
            );
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  callUploadDocument = (attributes: any, doc: any) => {
    try {
      new Promise((resolve, reject) => {
        this.props.handleDocumentUpload(
          this.props.guestTeamId,
          this.props.guestTemplateId,
          this.getInstanceId(),
          doc,
          resolve,
          reject
        );
      })
        .then((data) => {
          if (!isNull(data) && Array.isArray(data) && data.length) {
            this.setState(
              {
                docUpload: false,
              },
              () => {
                const prevValues = attributes['value']
                  ? attributes['value']
                  : [];
                this.formGenerator.onValueChange(attributes['name'], [
                  ...prevValues,
                  ...data,
                ]);
              }
            );
          }
        })
        .catch(() => {
          this.setState(
            {
              docUpload: false,
            },
            () => {
              const prevValues = attributes['value'] ? attributes['value'] : [];
              this.formGenerator.onValueChange(attributes['name'], prevValues);
            }
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  handleDocumentUpload = (attributes: any, doc: any) => {
    try {
      if (typeof doc !== 'undefined' && !isNull(doc) && attributes) {
        this.setState({ docUpload: true });
        this.callUploadDocument(attributes, doc);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleDocumentUpdateAndDownload = (
    attributes: any,
    doc: any,
    actionType: any
  ) => {
    const { type } = attributes;
    switch (type) {
      case 'image':
        this.handleImagesUploadAndDownload(attributes, doc, actionType);
        break;
      case 'document':
        this.handleDocumentUpload(attributes, doc);
        break;
      default:
        return;
    }
  };

  handleDeleteDocument = async (attributes: any, doc: any) => {
    try {
      if (typeof doc !== 'undefined' && !isNull(doc) && attributes) {
        const filePath = !isNull(doc['file_path']) ? doc['file_path'] : '';
        if (!isNull(filePath)) {
          this.setState({
            deleteDocument: true,
          });
          const deleteRes = await deleteDocumentFile(
            attributes,
            filePath,
            this.formGenerator
          );
          this.setState({ deleteDocument: deleteRes, deleteDocData: {} });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  handlePreviewDocument = (doc: any) => {
    try {
      downloadDocument(doc);
    } catch (err) {
      console.log(err);
    }
  };

  handleOpenImageModal = (modalImgData: any) => {
    this.setState({ openImgModal: true, modalImgData });
  };

  closeImgModal = () => {
    this.setState({ openImgModal: false, modalImgData: null });
  };

  handleOpenAlertDialog = (msg: string) => {
    this.setState({ openAlertDialog: true, alertMessage: msg });
  };

  closeAlertDailog = () => {
    this.setState({ openAlertDialog: false, alertMessage: '' });
  };

  handleOpenDeleteDocDailog = (data: any) => {
    this.setState({
      deleteDocDialog: true,
      deleteDocData: data,
    });
  };

  closeDeleteDocDailog = (option: string) => {
    if (option === 'yes') {
      this.setState({ deleteDocDialog: false }, () => {
        this.handleDeleteDocument(
          this.state.deleteDocData['attributes'],
          this.state.deleteDocData['doc']
        );
      });
    } else {
      this.setState({
        deleteDocDialog: false,
        deleteDocData: null,
      });
    }
  };

  renderNoGuestAccessToken = () => {
    return <p className="no-access-token">You do not have permission</p>;
  };

  verifyCallback = (response: object) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  };

  getParamsName = (name: string) => {
    switch (name) {
      case 'brand':
        return 'brand';
      case '_mo_subdivision':
        return '_mo_subdivision';
      case 'category':
        return 'category';
      default:
        return null;
    }
  };

  getSearchParams = () => {
    const searchParams = this.props.router.location.search;
    return queryString.parse(searchParams);
  };

  getCatalogQueryParams = () => {
    const parsedValue = this.getSearchParams();
    let query = '';
    if (!isNull(Object.keys(parsedValue))) {
      Object.keys(parsedValue).forEach((ele: any) => {
        if (!isNull(this.getParamsName(ele))) {
          const queryString = `${this.getParamsName(ele)}=${parsedValue[ele]}`;
          if (query.length) {
            query = `${query}&${queryString}`;
          } else {
            query = queryString;
          }
        }
      });
    }

    const field = this.state.extProps && this.state.extProps['attributes'];
    if (field && field.data_source && field.data_source.filterQuery) {
      query = !isNull(query)
          ? query + "&" + field.data_source.filterQuery
          : field.data_source.filterQuery;
  }
  

    return query;
  };

  getOTP = (attributes: any, userName: string, userNameType: string) => {
    new Promise((resolve, reject) => {
      this.props.getOTPForForm(userName, userNameType, resolve, reject);
    })
      .then((data) => {
        attributes['res'] = data;
        const { fields }: { fields: any } = this.state;
        const index = _.findIndex(fields, { name: attributes.name });
        fields[index] = { ...attributes };
        this.setState({ fields: fields });
      })
      .catch((error: any) => {
        console.error('Error fetching OTP:', error);
      });
  };

  setOtpModal = () => {
    this.setState({ openOtpModal: true });
  };

  closeOtpModal = () => {
    this.setState({ openOtpModal: false });
  };

  submit = () => {
    const formData = this.formGenerator.getValues();
    const emails: any = this.state.fields.find(
      (field) => field['type'] === 'email'
    );
    const phoneNumbers: any = this.state.fields.find(
      (field) => field['type'] === 'phone'
    );
    const email = emails && emails.name ? formData[emails.name] : undefined;
const phoneNumber = phoneNumbers && phoneNumbers.name ? formData[phoneNumbers.name] : undefined


    if (formData) {
      this.setState((prevState) => ({
        ...prevState,
        contactInfo: {
          ...prevState.contactInfo,
          email: email || '',
          phoneNumber: phoneNumber || null,
        },
      }));
      this.setOtpModal();
    }
  };

  render() {
    return (
      <div
        id="guest-user-create-form-root"
        style={{
          marginTop: '25px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="naveen-user-logo-wrapper">
          <img
            src={require('./../../../../../../assets/images/NaveenEnterprices/NE_logo.jpeg')}
            alt="logo"
            className="logo"
          />
        </div>
        <div className="guest-user-create-form-wrapper">
          {!this.state.noGuestAccessToken && (
            <div className="guest-user-create-form-header">
              <p>{this.getTitle()}</p>
            </div>
          )}
          {this.props.guestUserSpinner ||
          this.props.reportDetailsSpinner ||
          this.props.naveenCreateFormDataSpinner ||
          this.props.validateGuestUserOtpRequest ? (
            <Spinner />
          ) : (
            ''
          )}
          <div
            className="guest-user-form-content"
            style={{ justifyContent: 'center' }}
          >
            <div className="guest-user-form-left">
              {this.state.noGuestAccessToken ? (
                this.renderNoGuestAccessToken()
              ) : (
                <>
                  {!isNull(unsupportedMessage(this.state.fields)) && (
                    <p style={{ color: '#FA9917', margin: '10px' }}>
                      {unsupportedMessage(this.state.fields)}
                    </p>
                  )}
                  {this.state.fields.length > 0 ? (
                    <Form
                      className="form"
                      ref={(c: any) => (this.formGenerator = c)}
                      fields={this.state.fields}
                      formData={this.state.formData}
                      formSubmissionType={this.getButtonTitle().toLowerCase()}
                      renderComponent={this.renderComponent}
                      handleDocumentUpdateAndDownload={
                        this.handleDocumentUpdateAndDownload
                      }
                      handleOpenImageModal={this.handleOpenImageModal}
                      openAlertModal={this.handleOpenAlertDialog}
                      handleOpenDeleteDocDailog={this.handleOpenDeleteDocDailog}
                      handlePreviewDocument={this.handlePreviewDocument}
                      getOtp={this.getOTP}
                      refData={this.state.refData}
                    />
                  ) : (
                    ''
                  )}
                  {process.env.NODE_ENV === 'development' ? (
                    <div />
                  ) : (
                    <div className="captch-wrapper">
                      <Recaptcha
                        sitekey="6Lck6noUAAAAAKhl_U6YGmjDhv7IAK2pH9JDNKsJ"
                        render="explicit"
                        verifyCallback={(response: any) =>
                          this.verifyCallback(response)
                        }
                        onloadCallback={() => {}}
                      />
                    </div>
                  )}
                  {!this.state.noGuestAccessToken && (
                    <div className="guest-user-buttons-wrapper">
                      <Button
                        className={
                          this.props.naveenCreateFormDataSpinner ||
                          this.state.attachmentUploadStatus !== 'done' ||
                          this.state.docUpload ||
                          this.state.deleteDocument ||
                          !this.state.isVerified
                            ? 'form-disabled-button'
                            : 'form-create-button'
                        }
                        onClick={() => this.submit()}
                        disabled={
                          this.props.naveenCreateFormDataSpinner ||
                          this.state.attachmentUploadStatus !== 'done' ||
                          this.state.docUpload ||
                          this.state.deleteDocument ||
                          !this.state.isVerified
                        }
                      >
                        {this.getButtonTitle()}
                      </Button>
                    </div>
                  )}
                  {this.state.fields.length > 0 && (
                    <div className="powered-by-wrapper ne-powered-by-wrapper">
                      <div className="ne-policy-wrapper">
                        <a
                          href="https://naveenenterprises.in/policies.htm"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        <a
                          href="https://naveenenterprises.in/policies.htm"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Return and Refund Policy
                        </a>
                      </div>
                      <div className="powered-by-logo-wrapper">
                        <p>Powered by</p>
                        <img
                          src={require('./../../../../../../assets/images/userOnBoarding/logo-big.png')}
                          alt="logo"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {this.state.openCatalogModal ? (
              <CatalogNaveenEnt
                open={this.state.openCatalogModal}
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeCustomDataView}
                queryParams={this.getCatalogQueryParams()}
                fromNaveenEnt={true}
                searchParams={this.getSearchParams()}
              />
            ) : null}
            {this.state.openLookupModal ? (
              <Lookup
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeLookupModal}
                isAddLookupEnable={true}
                templateName={this.state.templateName}
              />
            ) : null}

            {this.state.openChecklistModal ? (
              <Checklist
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeChecklist}
              />
            ) : null}

            {this.state.openSelectModal ? (
              <Select
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeSelectModal}
              />
            ) : null}

            {this.state.openUserDirectoryModal ? (
              <UserDirectory
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeUserDirectoryModal}
              />
            ) : null}
            {this.state.openImgModal ? (
              <ImagePreviewModal
                open={this.state.openImgModal}
                closeModal={this.closeImgModal}
                imgObj={this.state.modalImgData}
              />
            ) : null}
          </div>
        </div>
        {/* <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage ||
            this.props.success.displayMessage ||
            this.props.selectedMsg
          }
          open={this.props.open}
        /> */}
        {this.state.openAlertDialog && (
          <AlertDailog
            open={this.state.openAlertDialog}
            message={this.state.alertMessage}
            handleClose={this.closeAlertDailog}
            showNoButton={false}
            yesButtonText={'Ok'}
          />
        )}
        {this.state.deleteDocDialog && (
          <AlertDailog
            open={this.state.deleteDocDialog}
            message={
              'Selected file will be deleted permanently. Are you sure you want to delete'
            }
            handleClose={this.closeDeleteDocDailog}
          />
        )}
        {this.state.openOtpModal && (
          <OTPModal
            openModal={this.state.openOtpModal}
            closeModal={this.closeOtpModal}
            teamId={this.props.guestTeamId}
            email={this.state.contactInfo.email}
            phoneNumber={this.state.contactInfo.phoneNumber}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    selectedMsg: state.reportReducer.selectedMsg,
    getReportDetailsSuccess: state.reportReducer.getReportDetailsSuccess,
    reportDetailsSpinner: state.reportReducer.reportDetailsSpinner,
    reportDetails: state.reportReducer.reportDetails,
    naveenCreateFormDataSuccess:
      state.reportReducer.naveenCreateFormDataSuccess,
    naveenCreateFormDataSpinner:
      state.reportReducer.naveenCreateFormDataSpinner,
    naveenCreateFormDataResponse:
      state.reportReducer.naveenCreateFormDataResponse,

    guestUserSpinner: state.reportReducer.guestUserSpinner,
    guestUserAccessTokenSuccess:
      state.reportReducer.guestUserAccessTokenSuccess,
    guestUserAccessToken: state.reportReducer.guestUserAccessToken,
    guestTemplateId: state.reportReducer.guestTemplateId,
    guestTeamId: state.reportReducer.guestTeamId,
    guestUserAccessTokenFailure:
      state.reportReducer.guestUserAccessTokenFailure,
    otpFormSuccess: state.reportReducer.otpFormSuccess,
    otpFormFailure: state.reportReducer.otpFormFailure,
    otpFormData: state.reportReducer.otpFormData,
    validateGuestUserOtpSuccess:
      state.reportReducer.validateGuestUserOtpSuccess,
    validateGuestUserOtpRequest:
      state.reportReducer.validateGuestUserOtpRequest,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getReportDetails,
      createNaveenFormData,
      setExtProps,
      setSelectedFieldValue,
      handleImagesUploadAndDownload,
      handleDocumentUpload,
      accessTokenForGuestUser,
      setGuestFormdata,
      getOTPForForm,
      getUserDirectoryDesignation,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NEStoreOrderForm));
