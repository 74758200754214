
import React, { Component } from 'react';
import { Modal, Button } from '@mui/material';
import SignaturePad from 'react-signature-canvas';
import moment from 'moment';
import './../../styles/inbox/signatureModal.css';
class SignatureModal extends Component<any, any> {
  wrapperRef: any;
  sigPad: any;
  constructor(props: any) {
    super(props);
    this.state = {
      renderSpinner: false,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  };
  handleClickSave = (base64: any) => {
    const fileName = this.props.attributes.label
      .replace(/^[\s_]+/g, '')
      .split(' ')
      .join('_');
    this.props.handleDocumentUpdateAndDownload(
      this.props.attributes,
      [
        {
          url: base64,
          file_name: `${fileName}_${moment().utc().valueOf()}`,
          mime_type: 'image/png',
        },
      ],
      'write'
    );
    this.props.closeModal();
  };
  render() {
    const props = this.props;
    return (
      <Modal open={props.open}>
        <div id="sig-preview-modal-wrapper">
          <div
            className="sig-preview-modal-container"
            ref={(node) => (this.wrapperRef = node)}
          >
            <div className="sig-preview-modal-header">
              <p>Signature</p>
              <i
                className="far fa-times-circle close-button"
                onClick={() => props.closeModal()}
              />
            </div>
            <div className="sig-preview-modal-body">
              <div className="sig-canvas-wrapper">
                <SignaturePad
                  canvasProps={{ className: 'sig-canvas' }}
                  ref={(ref: any) => {
                    this.sigPad = ref;
                  }}
                />
              </div>
              <div className="sig-btn-wrapper">
                <Button
                  className="sig-cancel-btn"
                  onClick={() => this.sigPad.clear()}
                  variant="outlined"
                >
                  Reset
                </Button>
                <Button
                  className="sig-save-btn"
                  onClick={() =>
                    this.handleClickSave(
                      this.sigPad.getTrimmedCanvas().toDataURL('image/png')
                    )
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default SignatureModal;
